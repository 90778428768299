import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { cloneDeep, difference, orderBy, union } from 'lodash';
import { AsyncOperation, Opportunity, Project, RootState, Version } from '@/types';
import {
  AreaDetails,
  ItemType,
  OpportunityDetails,
  SizingTreeState,
  TagDetails,
  TagOrder,
  SizingOrder
} from '@/types/SizingTree';
import { sizingTreeService } from '@/services';
import { cloneDeepExistingProperties, createProgressIndicatorAction } from '@/helpers';
import _ from 'lodash';

export const initialState = (): SizingTreeState => {
  return {
    treeLoaded: false,
    rootOpportunityId: undefined,
    rootOpportunity: {
      opportunityId: '',
      opportunityName: '',
      salesforceId: '',
      comment: '',
      id: undefined,
      areas: [],
      customer: '',
      rfqNumber: '',
      customerReference: '',
      nelesContact: '',
      opportunityDate: undefined,
      cpqQuotationNumber: ''
    },
    cache: {},
    tagOrder: 'originalOrder',
    sizingOrder: 'createdOn',
    tagOrderDirection: 'asc',
    sizingOrderDirection: 'asc',
    sizingIds: [],
    silIds: [],
    copiedSizing: {
      sizingId: '',
      sizingType: ''
    }
  };
};

const state: SizingTreeState = initialState();

const getters: GetterTree<SizingTreeState, RootState> = {
  rootOpportunityTree(state): any {
    return [
      ...state.rootOpportunity.areas.map((area) => ({
        id: area.areaId,
        name: area.areaName,
        type: ItemType.Area,
        area: area,
        isChecked: area.isChecked,
        children: area.tags.map((tag) => ({
          id: tag.tagId,
          name: tag.tagName,
          type: ItemType.Tag,
          tag: tag,
          areaId: area.areaId,
          isChecked: tag.isChecked,
          children: [
            ...(tag.sizings?.map((sizing) => ({
              id: sizing.sizingId,
              name: sizing.sizingName,
              type: ItemType.Sizing,
              sizing: sizing,
              parentTag: tag,
              tagId: tag.tagId,
              areaId: area.areaId,
              isChecked: sizing.isChecked,
              createdOn: sizing.createdOn
            })) ?? []),
            ...(tag.sils?.map((sil) => ({
              id: sil.silId,
              name: sil.silName,
              type: ItemType.SIL,
              sil: sil,
              parentTag: tag,
              tagId: tag.tagId,
              areaId: area.areaId,
              isChecked: sil.isChecked
            })) ?? [])
          ]
        }))
      }))
    ];
  },
  rootOpportunity(state): any {
    return state.rootOpportunity;
  },
  getTagById(state): (tagId: string) => TagDetails | undefined {
    return (tagId: string): TagDetails | undefined => {
      const cacheEntry: any = state.cache[tagId];

      if (cacheEntry !== undefined) {
        return cacheEntry;
      }

      let foundTag: TagDetails | undefined;

      for (const area of state.rootOpportunity.areas) {
        foundTag = area.tags.find((tag: TagDetails) => tag.tagId === tagId);

        if (foundTag !== undefined && foundTag.tagId !== undefined) {
          // TODO Error: [vuex] do not mutate vuex store state outside mutation handlers
          //state.cache[foundTag.tagId] = foundTag;
          return foundTag;
        }
      }

      return undefined;
    };
  },
  getAreaById(state): (areaId: string) => AreaDetails | undefined {
    return (areaId: string): AreaDetails | undefined => {
      const cacheEntry: any = state.cache[areaId];

      if (cacheEntry !== undefined) {
        return cacheEntry;
      }

      const foundArea = state.rootOpportunity.areas.find((area: AreaDetails) => area.areaId === areaId);

      if (foundArea !== undefined && foundArea.areaId !== undefined) {
        // TODO Error: [vuex] do not mutate vuex store state outside mutation handlers
        // state.cache[foundArea.areaId] = foundArea;
        return foundArea;
      }

      return undefined;
    };
  },
  areaChildCounts(state, getters): (areaId: string) => { tagCount: number; sizingCount: number } {
    return (areaId: string): { tagCount: number; sizingCount: number } => {
      const area: AreaDetails = getters.getAreaById(areaId);
      let tagCount = 0;
      let sizingCount = 0;

      if (area.tags) {
        tagCount = area.tags.length;
        sizingCount = area.tags.reduce((acc, curr) => acc + (curr.sizings ? curr.sizings.length : 0), 0);
      }

      return { tagCount, sizingCount };
    };
  },
  selectedSizingIds(state): string[] | undefined {
    return state.sizingIds?.map((sizingIds) => sizingIds);
  },
  selectedSilIds(state): string[] | undefined {
    return state.silIds?.map((silIds) => silIds);
  },
  selectedSizingAndSilIds(getters): string[] | undefined {
    const sizingIds = getters.sizingIds;
    const silIds = getters.silIds;
    return sizingIds.concat(silIds);
  },
  isSizingCopied(getters): boolean | undefined {
    return !_.isEmpty(getters.copiedSizing.sizingId);
  }
};

function sortSizingsAndSils(area: AreaDetails, primarySortProperty: SizingOrder) {
  area.tags.forEach((tag) => {
    if (primarySortProperty == 'sizingName') {
      tag.sizings = orderBy(
        tag.sizings,
        [primarySortProperty, 'createdOn' || -1],
        [state.sizingOrderDirection, state.sizingOrderDirection]
      );
    }

    if (primarySortProperty == 'createdOn') {
      tag.sizings = orderBy(
        tag.sizings,
        [primarySortProperty, 'sizingName'],
        [state.sizingOrderDirection, state.sizingOrderDirection]
      );
    }

    tag.sils = orderBy(tag.sils, (s) => s.silName!.toLowerCase(), state.sizingOrderDirection);
  });
}

export const mutations: MutationTree<SizingTreeState> = {
  OPPORTUNITY_DETAILS_LOADED(state: SizingTreeState, opportunity: OpportunityDetails) {
    state.treeLoaded = true;
    state.rootOpportunity = opportunity;
  },
  SORT_OPPORTUNITY_DETAILS(state: SizingTreeState) {
    if (state.tagOrder == 'originalOrder') {
      state.rootOpportunity.areas.forEach((area) => {
        area.tags = area.tags.map((tag) => {
          tag.order = !tag.sizings ? -1 : Math.min(...tag.sizings.map((s) => s.customerInquiryOriginalOrder ?? -1));
          return tag;
        });

        area.tags = orderBy(area.tags, ['order', 'tagName'], [state.tagOrderDirection, state.tagOrderDirection]);

        sortSizingsAndSils(area, state.sizingOrder);
      });
    }

    if (state.tagOrder === 'exportOrder') {
      state.rootOpportunity.areas.forEach((area) => {
        area.tags = orderBy(area.tags, (t) => t.tagNumber, [state.tagOrderDirection, state.tagOrderDirection]);

        sortSizingsAndSils(area, state.sizingOrder);
      });
    }

    if (state.tagOrder === 'tagName') {
      state.rootOpportunity.areas.forEach((area) => {
        area.tags = orderBy(area.tags, (t) => t.tagName?.toLowerCase(), [state.tagOrderDirection]);

        sortSizingsAndSils(area, state.sizingOrder);
      });
    }
  },
  CHANGE_ROOT_OPPORTUNITY(state: SizingTreeState, opportunityId: string | undefined) {
    const opportunityChanged = opportunityId !== state.rootOpportunityId;

    if (opportunityChanged || opportunityId == null) {
      Object.assign(state, initialState());
    }

    state.rootOpportunityId = opportunityId;
  },
  CHANGE_ROOT_OPPORTUNITY_ID(state: SizingTreeState, opportunityId: string | undefined) {
    state.rootOpportunityId = opportunityId;
  },
  CHANGE_TAG_ORDER(state: SizingTreeState, order: TagOrder) {
    state.tagOrder = order;
  },
  CHANGE_SIZING_ORDER(state: SizingTreeState, order: SizingOrder) {
    state.sizingOrder = order;
  },
  TAG_ORDER_DIRECTION(state: SizingTreeState) {
    state.tagOrderDirection = state.tagOrderDirection === 'asc' ? 'desc' : 'asc';
  },
  SIZING_ORDER_DIRECTION(state: SizingTreeState) {
    state.sizingOrderDirection = state.sizingOrderDirection === 'asc' ? 'desc' : 'asc';
  },
  CLEAR_CACHE(state: SizingTreeState) {
    state.cache = {};
  },
  CLEAR_SELECTED_SIZINGS_AND_SILS(state: SizingTreeState) {
    state.sizingIds = [];
    state.silIds = [];
  },
  SET_COPIED_SIZING_ID: (state: SizingTreeState, sizingId: string) => {
    state.copiedSizing.sizingId = sizingId;
  },
  SET_COPIED_SIZING_TYPE: (state: SizingTreeState, sizingType: string) => {
    state.copiedSizing.sizingType = sizingType;
  },
  SET_CHECKED_STATE: (state: SizingTreeState, params: { item: any; value: boolean }) => {
    const areasClone = cloneDeep(state.rootOpportunity.areas);

    const areaCount = state.rootOpportunity?.areas?.length ?? 0;
    const addedOrRemovedSizingIds: string[] = [];
    const addedOrRemovedSilIds: string[] = [];

    if (areaCount == 0) {
      return;
    }

    for (let i = 0; i < areasClone.length; i++) {
      const area = areasClone[i];
      let setAreaDescendants = false; // if area is checked, check all under it
      let areaChildrenSameValue = true;
      let areaChildrenValue = true;

      if (params.item.id === area.areaId) {
        setAreaDescendants = true;
        area.isChecked = params.value;
      }

      for (let j = 0; j < area.tags.length; j++) {
        const tag = area.tags[j];
        const sizingCount = tag.sizings?.length ?? 0;
        const silCount = tag.sils?.length ?? 0;
        let setTagChildren = false; // if tag is checked, check all under it
        let tagChildrenSameValue = true;
        let tagChildrenValue = true;

        if (setAreaDescendants) {
          tag.isChecked = params.value;
        } else if (params.item.id === tag.tagId) {
          setTagChildren = true;
          tag.isChecked = params.value;
        }

        for (let k = 0; k < sizingCount; k++) {
          const sizing = tag.sizings![k];

          if (setAreaDescendants || setTagChildren || sizing.sizingId === params.item.id) {
            sizing.isChecked = params.value;
            addedOrRemovedSizingIds.push(sizing.sizingId!);
          }

          if (k === 0) {
            tagChildrenValue = sizing.isChecked ?? false;
          } else if (sizing.isChecked !== tagChildrenValue) {
            tagChildrenSameValue = false;
          }
        }

        for (let k = 0; k < silCount; k++) {
          const sil = tag.sils![k];

          if (setAreaDescendants || setTagChildren || sil.silId === params.item.id) {
            sil.isChecked = params.value;
            addedOrRemovedSilIds.push(sil.silId!);
          }

          if (k === 0 && sizingCount === 0) {
            tagChildrenValue = sil.isChecked ?? false;
          } else if (sil.isChecked !== tagChildrenValue) {
            tagChildrenSameValue = false;
          }
        }

        if (tagChildrenSameValue) {
          tag.isChecked = tagChildrenValue;
        }

        if (j === 0) {
          areaChildrenValue = tag.isChecked ?? false;
        } else if (areaChildrenValue !== tag.isChecked) {
          areaChildrenSameValue = false;
        }
      }

      if (areaChildrenSameValue) {
        area.isChecked = areaChildrenValue;
      }
    }

    updateSelectedSilsOrSizings(state, 'sizingIds', addedOrRemovedSizingIds, params.value);
    updateSelectedSilsOrSizings(state, 'silIds', addedOrRemovedSilIds, params.value);

    Object.assign(state.rootOpportunity.areas, areasClone);
  },
  SET_TREELOADED: (state: SizingTreeState, value: boolean) => {
    state.treeLoaded = value;
  }
};

export const actions: ActionTree<SizingTreeState, RootState> = {
  loadTreeWithProgressIndicator: createProgressIndicatorAction('loadTree', AsyncOperation.LoadingTree),
  async loadTree({ commit, rootGetters, state, rootState, dispatch }, forceReload = false) {
    if ((!state.treeLoaded || forceReload) && state.rootOpportunityId != null) {
      const projectId: string | undefined = rootState.project.projectId;
      const version: Version | undefined = rootState.project.selectedVersion;
      const result: OpportunityDetails = await sizingTreeService.loadTree(
        state.rootOpportunityId,
        projectId,
        version?.id
      );

      if (projectId === '') {
        const project: Project = await dispatch('project/getProject', result.id, {
          root: true
        });
        await dispatch('project/getRevisions', project.id, {
          root: true
        });
      }

      result.salesforceId = result.salesforceId ?? '';
      commit(mutations.CLEAR_CACHE.name);
      commit(mutations.OPPORTUNITY_DETAILS_LOADED.name, result);
      commit(mutations.SORT_OPPORTUNITY_DETAILS.name);
      commit(mutations.CHANGE_ROOT_OPPORTUNITY_ID.name, result.opportunityId);
      commit(mutations.SET_COPIED_SIZING_ID.name, '');
      commit(mutations.SET_COPIED_SIZING_TYPE.name, '');
      commit(mutations.CLEAR_SELECTED_SIZINGS_AND_SILS.name);

      const props = rootGetters['opportunity/initializedSelectedOpportunity'];
      const updated: Opportunity = cloneDeepExistingProperties(result, props);
      commit('opportunity/UPDATE_SELECTED_OPPORTUNITY_DATA', updated, {
        root: true
      });
      commit('opportunity/CHANGE_SELECTED_OPPORTUNITY_ID', updated.opportunityId, {
        root: true
      });
    }
  },
  changeRootOpportunity({ commit }, opportunityId) {
    commit(mutations.CHANGE_ROOT_OPPORTUNITY.name, opportunityId);
  },
  changeTagOrdering({ commit, state }, order: TagOrder) {
    commit(mutations.CHANGE_TAG_ORDER.name, order);

    if (state.tagOrder === order) {
      commit(mutations.TAG_ORDER_DIRECTION.name);
    }

    commit(mutations.SORT_OPPORTUNITY_DETAILS.name);
  },
  changeSizingOrdering({ commit, state }, order: SizingOrder) {
    commit(mutations.CHANGE_SIZING_ORDER.name, order);

    if (state.sizingOrder === order) {
      commit(mutations.SIZING_ORDER_DIRECTION.name);
    }

    commit(mutations.SORT_OPPORTUNITY_DETAILS.name);
  },
  copySizing({ commit }, { sizingId, sizingType }) {
    commit(mutations.SET_COPIED_SIZING_ID.name, sizingId);
    commit(mutations.SET_COPIED_SIZING_TYPE.name, sizingType);
  }
};

function updateSelectedSilsOrSizings(state: SizingTreeState, listName: string, ids: string[], value: boolean) {
  const _state: any = state;

  if (value) {
    _state[listName] = union(_state[listName], ids);
  } else {
    _state[listName] = difference(_state[listName], ids);
  }
}

export const sizingTree: Module<SizingTreeState, RootState> = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
};
