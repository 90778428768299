import * as Msal from '@azure/msal';
import { IAuthenticationService } from './IAuthenticationService';
import { Ref, ref } from 'vue';
import { Store } from 'vuex';

class MsalAuthenticationService implements IAuthenticationService {
  tenantId: string = '65f8dbd7-eb30-4ddc-88b3-f1f6fbea6ba2';
  valmetIdp: string = 'https://sts.windows.net/65f8dbd7-eb30-4ddc-88b3-f1f6fbea6ba2/';

  private readonly nelprofSessionkey: string = 'nelprofUISession';
  private isAuthenticatedRef: Ref<boolean> = ref(false);

  public isSalesforceAuthentication: boolean = false;

  msalConfig: Msal.Configuration = {
    auth: {
      clientId: '84862099-ec1a-4dd9-887f-ff670be2aafb',
      authority: `https://login.microsoftonline.com/${this.tenantId}`,
      redirectUri: window.location.protocol + '//' + window.location.host,
      navigateToLoginRequestUrl: false,
      postLogoutRedirectUri: window.location.protocol + '//' + window.location.host
    },
    cache: {
      cacheLocation: 'sessionStorage',
      storeAuthStateInCookie: false
    }
  };
  authRequest: Msal.AuthenticationParameters = {
    scopes: ['d8be49f9-ef50-4f5a-9b75-30214ec8a582/user_impersonation']
  };
  _msal: any;

  constructor() {
    this.startSession();
  }

  get userName(): string {
    if (this.isAuthenticated.value) {
      return this.msal.getAccount().name;
    }

    return '';
  }

  get nelesContact(): string {
    return this.msal.getAccount().name;
  }

  get isValmetUser(): boolean {
    return true;
  }

  get isFetchingRefreshToken(): boolean {
    return false;
  }

  setup(store: Store<any>): void {
    if (typeof localStorage !== 'undefined') {
      const requestedUrl = localStorage.getItem('requestedUrl');

      if (
        requestedUrl !== null &&
        window.location.href.indexOf('#') !== -1 &&
        requestedUrl !== window.location.pathname
      ) {
        //pop-up has been blocked
        if (window.location.href.indexOf('#id_token=') !== -1) {
          window.location.href = requestedUrl;
        } else {
          localStorage.removeItem('requestedUrl');
          window.location.href = requestedUrl;
        }
      }
    }
  }

  login(): void {
    this.msal.loginRedirect(this.authRequest);
  }

  logout(): void {
    if (this.sessionHas('accessToken') && this.sessionHas('accessTokenExpiresOn')) {
      this.sessionRemove('accessToken');
      this.sessionRemove('accessTokenExpiresOn');
    }

    this.msal.logout();
  }

  get isAuthenticated(): Ref<boolean> {
    if (this.msal.getAccount()) {
      this.isAuthenticatedRef.value = true;
    } else {
      this.isAuthenticatedRef.value = false;
    }

    return this.isAuthenticatedRef;
  }

  async getToken(): Promise<String> {
    if (this.msal.getAccount() === null) {
      throw new Error('Token retrieve failed');
    }

    this.startSession();

    if (this.sessionHas('accessToken') && this.sessionHas('accessTokenExpiresOn')) {
      const expireOn = new Date(this.sessionGet('accessTokenExpiresOn') ?? '');
      const currentDate = new Date();

      if (currentDate < expireOn) {
        return this.sessionGet('accessToken') ?? '';
      } else {
        this.sessionRemove('accessToken');
        this.sessionRemove('accessTokenExpiresOn');
      }
    }

    // if the user is already logged in you can acquire a token
    const tokenPromise = new Promise<String>((resolve) => {
      this.msal
        .acquireTokenSilent(this.authRequest)
        .then((response) => {
          // get access token from response
          this.sessionSet('accessToken', response.accessToken);
          this.sessionSet('accessTokenExpiresOn', response.expiresOn);

          resolve(response.accessToken);
        })
        .catch((err) => {
          // could also check if err instance of InteractionRequiredAuthError if you can import the class.
          if (err.name === 'InteractionRequiredAuthError') {
            return this.msal
              .acquireTokenPopup(this.authRequest)
              .then((response) => {
                // get access token from response
                this.sessionSet('accessToken', response.accessToken);
                this.sessionSet('accessTokenExpiresOn', response.expiresOn);

                resolve(response.accessToken);
              })
              .catch(() => {
                // handle error
                this.msal.acquireTokenRedirect(this.authRequest);
                resolve('');
              });
          }
        });
    });

    return tokenPromise;
  }

  private get msal(): Msal.UserAgentApplication {
    if (!this._msal) {
      this._msal = new Msal.UserAgentApplication(this.msalConfig);
    }

    return this._msal;
  }

  private sessionHas(key: string): boolean {
    const session = this.parse(localStorage.getItem(this.nelprofSessionkey));

    if (!session) {
      return false;
    }

    return key in session;
  }

  private sessionGet(key: string): string | null {
    const session = this.parse(localStorage.getItem(this.nelprofSessionkey));

    if (!session || !(key in session)) {
      return null;
    }

    return session[key];
  }

  private sessionRemove(key: string): void {
    const session = this.parse(localStorage.getItem(this.nelprofSessionkey));

    if (!session || !(key in session)) {
      return;
    }

    delete session[key];
    localStorage.setItem(this.nelprofSessionkey, JSON.stringify(session));
  }

  private sessionSet(key: string, value: any): void {
    const session = this.parse(localStorage.getItem(this.nelprofSessionkey));

    if (!session) {
      return;
    }

    session[key] = value;
    localStorage.setItem(this.nelprofSessionkey, JSON.stringify(session));
  }

  private startSession(): void {
    const session = this.parse(localStorage.getItem(this.nelprofSessionkey));

    if (!session) {
      localStorage.setItem(this.nelprofSessionkey, '{}');
    }
  }

  private parse(json: string | null): any {
    let data: any;

    if (!json) {
      return null;
    }

    try {
      data = JSON.parse(json);
    } catch (e) {
      return null;
    }

    return data;
  }
}

export default MsalAuthenticationService;
