import { RouteLocationNormalized, createRouter, createWebHistory } from 'vue-router';
import Home from '@/views/Home.vue';
import NotFound from '@/views/NotFound.vue';
import { authenticationService } from '@/services/AuthenticationService';
import SalesforceAuthenticationService from '@/services/salesforceAuthenticationService';

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/notfound',
    name: '404',
    component: NotFound
  },
  {
    path: '/opportunities',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "opportunities" */ '../views/Opportunities.vue'),
    props: true,
    children: [
      {
        path: ':opportunityId',
        name: 'opportunity',
        component: () => import(/* webpackChunkName: "opportunity" */ '../components/OpportunityEditor.vue'),
        props: true
      },
      {
        path: ':opportunityId/revisions',
        name: 'revisions',
        component: () => import(/* webpackChunkName: "revisions" */ '../components/RevisionList.vue'),
        props: true
      },
      {
        name: 'area',
        path: ':opportunityId/areas/:areaId',
        component: () => import(/* webpackChunkName: "area" */ '../components/AreaEditor.vue'),
        props: true
      },
      {
        name: 'tag',
        path: ':opportunityId/areas/:areaId/tags/:tagId',
        component: () => import(/* webpackChunkName: "tag" */ '../components/TagEditor.vue'),
        props: true
      },
      {
        name: 'createTag',
        path: ':opportunityId/areas/:areaId/tags/:tagId/:createdType',
        component: () => import(/* webpackChunkName: "tag" */ '../components/TagEditor.vue'),
        props: true
      },
      {
        name: 'sizing',
        path: ':opportunityId/areas/:areaId/tags/:tagId/sizing/:sizingId',
        component: () => import(/* webpackChunkName: "sizing" */ '../components/SizingEditor.vue'),
        props: true,
        meta: { requiresAuth: true }
      },
      {
        name: 'sil',
        path: ':opportunityId/areas/:areaId/tags/:tagId/sil/:silId',
        component: () => import(/* webpackChunkName: "sil" */ '../components/SIL/SilEditor.vue'),
        props: true,
        meta: { requiresAuth: true }
      },
      {
        path: '',
        name: 'myopportunities',
        component: () => import(/* webpackChunkName: "myOpportunities" */ '../components/MyOpportunities.vue')
      }
    ]
  },
  {
    path: '/help',
    name: 'help',
    component: () => import(/* webpackChunkName: "help" */ '../views/Help.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/releases',
    name: 'releases',
    component: () => import(/* webpackChunkName: "releases" */ '../views/Releases.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/dataprivacy',
    name: 'dataPrivacy',
    component: () => import(/* webpackChunkName: "dataprivacy" */ '../views/DataPrivacy.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/termsofuse',
    name: 'termsofuse',
    component: () => import(/* webpackChunkName: "termsofuse" */ '../views/TermsOfUse.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: NotFound
  },
  // if you omit the last `*`, the `/` character in params will be encoded when resolving or pushing
  {
    path: '/:pathMatch(.*)',
    name: 'bad-not-found',
    component: NotFound
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  if (authenticationService.isSalesforceAuthentication) {
    checkSalesforceAccessToken(to);
  }

  const isAuthenticated = authenticationService.isAuthenticated.value;

  if (to.matched.some((record) => record.meta.requiresAuth) && !isAuthenticated) {
    if (typeof localStorage !== 'undefined') {
      if (to.fullPath !== '/') {
        localStorage.setItem('requestedUrl', to.fullPath);
      }
    }

    authenticationService.login(to.path);
  } else {
    next();
  }
});

// bad example if using named routes:
router.resolve({
  name: 'bad-not-found',
  params: { pathMatch: 'not/found' }
}).href; // '/not%2Ffound'
// good example:
router.resolve({
  name: 'not-found',
  params: { pathMatch: ['not', 'found'] }
}).href; // '/not/found'

function checkSalesforceAccessToken(to: RouteLocationNormalized) {
  const accessToken: string | undefined = to.query?.accessToken?.toString();
  const accessTokenExpiresOn: string | undefined = to.query?.accessTokenExpiresOn?.toString();
  const refreshToken: string | undefined = to.query?.refreshToken?.toString();
  const userName: string | undefined = to.query?.userName?.toString();
  const isValmetUser: boolean = to.query?.isValmetUser?.toString() === 'true';

  // salesforce login thing
  if (accessToken !== undefined) {
    // save tokens to session with auth service
    const newQuery = to.query as any;
    to.query;

    delete newQuery['accessToken'];
    delete newQuery['accessTokenExpiresOn'];
    delete newQuery['refreshToken'];
    delete newQuery['userName'];
    delete newQuery['isValmetUser'];

    router.replace({ path: to.path, query: {} });
    (authenticationService as SalesforceAuthenticationService).setSession(
      accessToken,
      accessTokenExpiresOn,
      refreshToken,
      userName,
      isValmetUser
    );
  }
}

export { router, routes };
