import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { cloneDeep, get, isEmpty, omit, pick } from 'lodash';
import { calculationService, Convert, exportService, sizingService } from '@/services';
import {
  Actuator,
  AsyncOperation,
  Attenuator,
  ConditionalRequest,
  ControlFlowCalculationRequest,
  ControlFlowResult,
  DefaultUnits,
  Diffuser1Stg,
  Diffuser2Stg,
  FlowCase,
  FlowCaseRequest,
  FlowCaseResult,
  FlowCases,
  FlowCaseUnits,
  FluidType,
  GasFlowCase,
  GasMassType,
  LiquidDensityType,
  LiquidFlowCase,
  MetadataRequest,
  Opportunity,
  PipelineSizes,
  ProcessMedium,
  ProcessUnitsRequest,
  PulpType,
  Resistor,
  RootState,
  SafetyFactorSelectionOption,
  SizingState,
  Tag,
  ThicknessType,
  ThirdPartyValveFlowCase,
  TwoPhaseFlowCase,
  Valve,
  ValveFeaturesControl,
  ValveFunction,
  ValveMotion,
  ViscosityType
} from '@/types';
import { cloneDeepExistingProperties, createProgressIndicatorAction } from '@/helpers';
import { createActionWithUndetectedEdit, isEditedMutations } from '@/store/plugins';
import { emptyFlowCase } from '@/services/FluidTypeSelection';
import { hasNonEmptyFlowCaseResults } from '@/helpers/checkEmptyResults';

const state: SizingState = {
  reload: 0,
  isEdited: false,
  isEditingAutoDetected: true,
  editingAutoDetectDisabledCount: 0,
  tagId: undefined,
  sizingId: undefined,
  deviceId: undefined,
  valveFunction: ValveFunction.Control,
  sizingName: 'control',
  comments: {
    internalComment: undefined,
    externalComment: undefined
  },
  processMedium: initialProcessMedium(),
  pipelineSizes: initialPipelineSizes(),
  flowCases: intialFlowCases(),
  valve: initialValve(),
  attenuator: initialAttenuator(),
  resistor: initialResistor(),
  actuator: initialActuator(),
  result: null,
  actuatorResult: null,
  controlflowRequest: null,
  isError: false,
  stateAfterSizingLoaded: undefined,
  stateAfterDropDownOptiosLoaded: undefined
};

export const getters: GetterTree<SizingState, RootState> = {
  valveFunction(state): ValveFunction {
    return state.valveFunction || ValveFunction.Control;
  },
  maxCases: (state): number => state.flowCases.maxCases,
  caseCount: (state): number => state.flowCases.caseCount,
  fluidType: (state): FluidType => state.processMedium.fluidType,
  isLiquid(state): boolean {
    switch (state.processMedium.fluidType) {
      case FluidType.Liquid:
      case FluidType.Pulp:
      case FluidType.TwoPhase:
        return true;
      default:
        return false;
    }
  },
  isPulp(state): boolean {
    return state.processMedium.fluidType === FluidType.Pulp;
  },
  isGas(state): boolean {
    switch (state.processMedium.fluidType) {
      case FluidType.Gas:
      case FluidType.TwoPhase:
        return true;
      default:
        return false;
    }
  },
  isAutomaticSize(state): boolean {
    return !state.valve.size;
  },
  isAutomaticReduction(state, getters): boolean {
    return !(state.valve.sizeReduction || state.valve.sizeReduction === 0) || getters.isAutomaticSize;
  },
  shouldCalculateCharts(state, getters) {
    return !(
      getters.valveFunction === ValveFunction.OnOff ||
      getters.fluidType === FluidType.TwoPhase ||
      getters.valve.isThirdPartyValve
    );
  },
  valveSize(state, getters): number | undefined {
    if (getters.isAutomaticSize) {
      return state.valve.autoSize;
    } else {
      return state.valve.size;
    }
  },
  valveSizeReduction(state, getters): number | undefined {
    if (getters.isAutomaticReduction) {
      return state.valve.autoSizeReduction;
    } else {
      return state.valve.sizeReduction;
    }
  },
  gasMassType(state): GasMassType {
    return state.processMedium.gasMassType;
  },
  liquidDensityType(state): string | undefined {
    return state.processMedium.liquidDensityType;
  },
  valveMotion(state): ValveMotion {
    return state.valve.valveMotion || ValveMotion.None;
  },
  torqueUnit(state): string {
    const value = state.processMedium.torqueUnit;

    if (state.valve.valveMotion !== ValveMotion.Linear) {
      return value !== 'ftlb' ? 'Nm' : value;
    } else {
      switch (value) {
        case 'kgf':
        case 'lbf':
          return value;
        default:
          return 'N';
      }
    }
  },
  capacityUnit(state): string {
    return state.processMedium.capacityUnit;
  },
  processMedium(state, getters): ProcessMedium {
    const props = [
      'fluidType',
      'description',
      'flowVelocityUnit',
      'capacityUnit',
      'designPressureUnit',
      'designPressureMin',
      'designPressureMax',
      'designTemperatureUnit',
      'designTemperatureMin',
      'designTemperatureMax'
    ];

    if (getters.valveFunction === ValveFunction.OnOff) {
      props.push('inletTemperatureUnit', 'minInletTemperature', 'maxInletTemperature');
    }

    if (getters.isLiquid) {
      props.push('liquidDensityType', 'criticalPressureUnit', 'criticalPressure');

      if (!getters.isPulp) {
        props.push('liquid');
      }

      const liquidDensityType = state.processMedium.liquidDensityType;

      if (liquidDensityType == LiquidDensityType.Gravity) {
        props.push('liquidSpecificGravity');
      }

      if (liquidDensityType == LiquidDensityType.Density) {
        props.push('liquidDensity', 'liquidDensityUnit');
      }
    }

    if (getters.isPulp) {
      props.push('pulpConsistency', 'pulpType');
    }

    if (getters.isGas) {
      props.push('gas', 'gasMassType', 'ratioOfSpecificHeats');

      const gasMassType = state.processMedium.gasMassType;

      if (gasMassType === GasMassType.MolecularWeight) {
        props.push('gasMolecularWeight');
      }

      if (gasMassType === GasMassType.SpecificGravity) {
        props.push('gasSpecificGravity');
      }
    }

    if (get(state, ['actuator', 'active'], false)) {
      props.push(
        'phaseType',
        'supplyPressureUnit',
        'supplyPressure',
        'shutoffPressureiDfferenceUnit',
        'shutoffPressureiDfference',
        'torqueUnit'
      );
    }

    return pick(state.processMedium, props) as ProcessMedium;
  },
  pipelineSizes(state): any {
    const props = ['unit', 'inletSize', 'outletSize', 'thicknessType', 'insulationType', 'insulationThickness'];

    if (state.pipelineSizes.thicknessType === ThicknessType.Thickness) {
      props.push('thickness');
    } else {
      props.push('schedule');
    }

    return pick(state.pipelineSizes, props);
  },
  actuator_active(state): boolean {
    return get(state, ['actuator', 'active'], false);
  },
  actuator_safetyFactorSelectionOption(state): SafetyFactorSelectionOption {
    let sfSelection = state.actuator.safetyFactorSelectionOption;

    if (state.actuator.safetyFactor) {
      sfSelection = SafetyFactorSelectionOption.Custom;
    }

    return sfSelection ? sfSelection : SafetyFactorSelectionOption.Automatic;
  },
  resistor_active(state): boolean {
    return get(state, ['resistor', 'active'], false);
  },
  attenuator_active(state): boolean {
    return get(state, ['attenuator', 'active'], false);
  },
  units(state, getters): FlowCaseUnits {
    const props = ['inletTemperatureUnit', 'inletPressureUnit', 'pressureDifferenceUnit'];

    if (getters.isLiquid) {
      props.push('liquidFlowRateUnit', 'vapourPressureUnit');

      if (getters.fluidType == FluidType.Liquid) {
        props.push('kinematicViscosityUnit', 'dynamicViscosityUnit', 'viscosityType');
      }

      if (getters.liquidDensityType === LiquidDensityType.Density) {
        props.push('liquidDensityUnit');
      }
    }

    if (getters.isGas) {
      props.push('gasFlowRateUnit');

      if (getters.gasMassType === GasMassType.InletDensity) {
        props.push('inletDensityUnit');
      }
    }

    return pick(state.flowCases.units, props) as FlowCaseUnits;
  },
  flowCases(state, getters): FlowCase[] {
    const props = ['caseId', 'caseGuid'];

    if (state.valve?.isThirdPartyValve) {
      if (getters.valveMotion === ValveMotion.Rotary) {
        props.push('runToClose', 'runToOpen', 'travel');
      }
    } else {
      props.push('inletTemperature', 'inletPressure', 'outletPressure', 'pressureDifference');

      if (getters.isLiquid) {
        props.push('liquidFlowRate', 'vapourPressure');

        if (getters.fluidType === FluidType.Liquid) {
          props.push('kinematicViscosity', 'dynamicViscosity', 'viscosityType');
        }

        if (getters.liquidDensityType === LiquidDensityType.Density) {
          props.push('liquidDensity');
        }
      }

      if (getters.isGas) {
        props.push('gasFlowRate');

        if (getters.gasMassType === GasMassType.InletDensity) {
          props.push('inletDensity');
        } else {
          props.push('compressibility');
        }
      }
    }

    return state.flowCases.cases.map((flowCase) => pick(flowCase, props)) as FlowCase[];
  },
  cases: (state): FlowCase[] => cloneDeep(state.flowCases.cases),
  validCaseIndexes: (state): number[] => cloneDeep(state.flowCases.validFlowCaseIndexes),
  valve: (state): Valve => Object.assign({}, state.valve),
  caseId({ flowCases }): any {
    return (index: number) => get(flowCases, ['cases', index, 'caseId']);
  },
  flowcaseByIndex: (state) => (value: number) => {
    return state.flowCases.cases[value];
  },
  flowCaseRequest: (state, getters): FlowCaseRequest[] => {
    return state.flowCases.cases
      .slice(0, state.flowCases.caseCount)
      .map((flowCase: any, index) => {
        if (state.flowCases.validFlowCaseIndexes.includes(index)) {
          const request: FlowCaseRequest = {
            caseId: flowCase.caseId || caseId(index + 1),
            caseGuid: state.flowCases.cases[index].caseGuid || '00000000-0000-0000-0000-000000000000',
            inletTemperature: flowCase.inletTemperature,
            pressureDifference: flowCase.pressureDifference,
            inletPressure: flowCase.inletPressure
          };

          if (getters.isLiquid) {
            request.flowRateLiquid = flowCase.liquidFlowRate;
            request.vapourPressure = flowCase.vapourPressure;

            if (getters.fluidType === FluidType.Liquid) {
              if (state.flowCases.units.viscosityType == ViscosityType.Kinematic) {
                request.kinematicViscosity = flowCase.kinematicViscosity;
              } else {
                request.dynamicViscosity = flowCase.dynamicViscosity;
              }
            }

            if (state.processMedium.liquidDensityType === LiquidDensityType.Density) {
              request.liquidDensity = flowCase.liquidDensity
                ? flowCase.liquidDensity
                : state.processMedium.liquidDensity; //backwards combatibility. this was used before liquid density was added at flowcases.
            }
          }

          if (getters.isGas) {
            if (flowCase.gasFlowRate !== undefined) {
              request.flowRateGas = flowCase.gasFlowRate;
            }

            if (state.processMedium.gasMassType === GasMassType.InletDensity) {
              if (flowCase.inletDensity !== undefined) {
                request.inletDensity = flowCase.inletDensity;
              }
            } else {
              if (flowCase.compressibility !== undefined) {
                request.compressibility = flowCase.compressibility;
              }
            }
          }

          return request;
        } else {
          return undefined;
        }
      })
      .filter((flowCase) => flowCase !== undefined) as FlowCaseRequest[];
  },
  conditionalRequest: (state, getters): ConditionalRequest => {
    const pipelineSizes = state.pipelineSizes;
    const processMedium = state.processMedium;
    const conditions: ConditionalRequest = {
      fluidPhase: processMedium.fluidType,
      pipediameterUnit: pipelineSizes.unit,
      inletDiameter: pipelineSizes.inletSize,
      outletDiameter: pipelineSizes.outletSize,
      insulationType: pipelineSizes.insulationType,
      insulationThickness: pipelineSizes.insulationThickness
    };

    if (pipelineSizes.thicknessType === ThicknessType.Thickness) {
      conditions.thickness = pipelineSizes.thickness;
    } else {
      conditions.schedule = pipelineSizes.schedule;
    }

    if (getters.isLiquid) {
      if (processMedium.liquidDensityType === LiquidDensityType.Gravity) {
        conditions.liquidSpecificGravity = processMedium.liquidSpecificGravity;
      }

      conditions.criticalPressureUnit = processMedium.criticalPressureUnit;
      conditions.criticalPressure = processMedium.criticalPressure;
    }

    if (getters.isPulp) {
      conditions.pulpConsistency = processMedium.pulpConsistency;
      conditions.pulpType = processMedium.pulpType;
    }

    if (getters.isGas) {
      if (processMedium.gasMassType === GasMassType.SpecificGravity) {
        conditions.gasSpecificGravity = processMedium.gasSpecificGravity;
      }

      if (processMedium.gasMassType === GasMassType.MolecularWeight) {
        conditions.molecularWeight = processMedium.gasMolecularWeight;
      }

      conditions.specificHeats = processMedium.ratioOfSpecificHeats;
    }

    const fluidType = get(state, ['processMedium', 'fluidType']);

    if (fluidType === FluidType.Liquid && get(state, ['resistor', 'active'])) {
      conditions.valvePressureDifferenceWithResistor = get(state, ['resistor', 'pressureDifference']);
    }

    return conditions;
  },
  processUnitsRequest: (state, getters): ProcessUnitsRequest => {
    const processUnits: ProcessUnitsRequest = {
      flowRateUnitGas: state.flowCases.units.gasFlowRateUnit,
      flowRateUnitLiquid: state.flowCases.units.liquidFlowRateUnit,
      inletPressureUnit: state.flowCases.units.inletPressureUnit,
      pressureDifferenceUnit: state.flowCases.units.pressureDifferenceUnit,
      inletTemperatureUnit: state.flowCases.units.inletTemperatureUnit,
      capacityUnit: state.processMedium.capacityUnit,
      flowVelocityUnit: state.processMedium.flowVelocityUnit
    };

    if (getters.isLiquid) {
      processUnits.vapourPressureUnit = state.flowCases.units.vapourPressureUnit;
      processUnits.flowRateUnitLiquid = state.flowCases.units.liquidFlowRateUnit;

      if (getters.fluidType === FluidType.Liquid) {
        if (state.flowCases.units.viscosityType == ViscosityType.Kinematic) {
          processUnits.kinematicViscosityUnit = state.flowCases.units.kinematicViscosityUnit;
        } else {
          processUnits.dynamicViscosityUnit = state.flowCases.units.dynamicViscosityUnit;
        }

        processUnits.viscosityType = state.flowCases.units.viscosityType;
      }

      if (state.processMedium.liquidDensityType === LiquidDensityType.Density) {
        processUnits.liquidDensityUnit =
          state.flowCases.units.liquidDensityUnit === undefined
            ? state.processMedium.liquidDensityUnit
            : state.flowCases.units.liquidDensityUnit;
      }
    }

    if (getters.isGas) {
      processUnits.flowRateUnitGas = state.flowCases.units.gasFlowRateUnit;

      if (state.processMedium.gasMassType === GasMassType.InletDensity) {
        processUnits.inletDensityUnit = state.flowCases.units.inletDensityUnit;
      }
    }

    return processUnits;
  },
  valveFeaturesControlRequest: (state): ValveFeaturesControl => {
    const request: ValveFeaturesControl = {
      valveMotion: state.valve.valveMotion || '',
      type: state.valve.seriesType || state.valve.type || '',
      series: state.valve.series || '',
      designStyle: state.valve.designStyle || '',
      pressureClass: state.valve.pressureClass,
      trimOption: state.valve.trimOption || '',
      trimCharacteristic: state.valve.trimCharacteristics,
      seatCode: state.valve.seatCode || '',
      flowDirection: state.valve.flowDirection
    };

    if (state.valve.size) {
      request.size = state.valve.size;
      request.reduction = state.valve.sizeReduction;
    }

    const fluidType = get(state, ['processMedium', 'fluidType']);

    if (fluidType === FluidType.Gas && get(state, ['attenuator', 'active'])) {
      request.resistors = [
        {
          id: '0',
          type: state.attenuator.type,
          size: state.attenuator.size,
          pressureDifference: state.attenuator.pressureDifference
        }
      ];

      // Diffusers have pressureDifference, but no size
      if (state.attenuator.type === Diffuser1Stg || state.attenuator.type === Diffuser2Stg) {
        request.resistors[0].size = undefined;
      } else {
        request.resistors[0].pressureDifference = undefined;
      }
    }

    if (fluidType === FluidType.Liquid && get(state, ['resistor', 'active'])) {
      request.resistors = [
        {
          id: '0',
          type: state.resistor.type ? state.resistor.type.toLowerCase() : undefined,
          size: state.resistor.size
        }
      ];
    }

    return request;
  },
  metadata: (state: SizingState, getters, rootState, rootGetters): MetadataRequest => {
    const request: MetadataRequest = {};

    if (state.processMedium.fluidType === FluidType.Liquid) {
      const liquid = state.processMedium.liquid;

      if (liquid) {
        request.liquid = liquid;
      }
    }

    if (state.processMedium.fluidType === FluidType.Gas) {
      const gas = state.processMedium.gas;

      if (gas) {
        request.gas = gas;
      }
    }

    const description = state.processMedium.description;

    if (description) {
      request.description = description;
    }

    if (state.tagId) {
      const tag = rootGetters['sizingTree/getTagById'](state.tagId);
      request.tagName = tag ? tag.tagName : undefined;
    }

    return request;
  },
  calculationRequest: (_, getters): ControlFlowCalculationRequest => ({
    valveFeaturesControl: getters.valveFeaturesControlRequest,
    conditional: getters.conditionalRequest,
    processUnits: getters.processUnitsRequest,
    flowCases: getters.flowCaseRequest,
    metadata: getters.metadata,
    resultScope: { frequencySpectrum: true, numberOfSizingResults: 1 }
  }),
  controlflowRequest: (_, getters): any => getters.calculationRequest,
  controlFlowResult(state): ControlFlowResult | null {
    return state.result;
  },
  actuatorResult(state): any | null {
    return cloneDeep(state.actuatorResult);
  },
  deviceData(state): any {
    const fluidType = get(state, ['processMedium', 'fluidType']);
    const hasActuator = get(state, ['actuator', 'active'], false);
    const props = [
      'valveFunction',
      'type',
      'pressureClass',
      'size',
      'autoSize',
      'sizeReduction',
      'autoSizeReduction',
      'designStyle',
      'flowDirection',
      'seatCode',
      'trimCharacteristics',
      'trimOption'
    ];
    const thirdPartyValveProps = ['breakToOpen', 'endToClose', 'isThirdPartyValve', 'valveMotion'];

    if (state.valve.isThirdPartyValve) {
      if (
        state.valveFunction === ValveFunction.OnOff ||
        (state.valveFunction === ValveFunction.Control && state.valve.valveMotion === ValveMotion.Linear)
      ) {
        thirdPartyValveProps.push('runToOpen', 'endToOpen', 'breakToClose', 'runToClose');
      }
    }

    if (state.valve.series) {
      props.push('series', 'valveMotion', 'seriesType');
    }

    if (hasActuator) {
      props.push('seatType', 'packingMaterial');

      if (state.valve.valveMotion === ValveMotion.Rotary) {
        props.push('bearingMaterial', 'seatAmount');
      } else if (state.valve.valveMotion === ValveMotion.Linear) {
        props.push('trimType', 'seatLeakage', 'bonnetType');
      }
    }

    const device: any = state.valve.isThirdPartyValve
      ? { valve: pick(state.valve, thirdPartyValveProps) }
      : { valve: pick(state.valve, props) };

    if (hasActuator) {
      device.actuator = omit(state.actuator, ['active']);
    }

    if (fluidType === FluidType.Gas && get(state, ['attenuator', 'active'])) {
      device.attenuator = omit(state.attenuator, ['active']);
    } else if (fluidType === FluidType.Liquid && get(state, ['resistor', 'active'])) {
      device.resistor = omit(state.resistor, ['active']);
    }

    return device;
  },
  resultData(state) {
    return {
      result: state.result
    };
  },
  actuatorResultData(state) {
    const result = state.actuatorResult;
    return result ? { actuatorResult: result } : undefined;
  },
  processData(state, getters): any {
    const result: any = {
      processMedium: getters.processMedium,
      pipelineSizes: getters.pipelineSizes
    };

    if (getters.valveFunction === ValveFunction.Control) {
      result.flowCaseUnits = getters.units;
      result.flowCases = getters.flowCases;
    }

    return result;
  },
  actuatorProcessData: function (state, getters): any {
    let inletTemperatureUnit;
    let minInletTemperature;
    let maxInletTemperature;

    if (getters.valveFunction === ValveFunction.OnOff) {
      inletTemperatureUnit = state.processMedium.inletTemperatureUnit;
      minInletTemperature = state.processMedium.minInletTemperature;
      maxInletTemperature = state.processMedium.maxInletTemperature;
    } else {
      const temperatures = state.flowCases.cases
        .map((flowCase) => flowCase.inletTemperature)
        .filter((temp) => typeof temp === 'number') as number[];
      inletTemperatureUnit = state.flowCases.units.inletTemperatureUnit;
      minInletTemperature = Math.min(...temperatures);
      maxInletTemperature = Math.max(...temperatures);
    }

    return {
      dPShutOff: state.processMedium.shutoffPressureiDfference,
      dPShutOffUnit: state.processMedium.shutoffPressureiDfferenceUnit,
      inletTemperatureUnit,
      minInletTemperature,
      maxInletTemperature,
      supplyPressure: state.processMedium.supplyPressure,
      supplyPressureUnit: state.processMedium.supplyPressureUnit,
      fluidPhase: state.processMedium.fluidType,
      fluidType: state.processMedium.phaseType,
      torqueForceUnit: getters.torqueUnit
    };
  },
  actuatorFeatures: (state) => ({
    series: state.actuator.series,
    springAction: state.actuator.springAction,
    size: state.actuator.size,
    springRange: state.actuator.springRange,
    minimumSize: state.actuator.minimumSize || 0
  }),
  valveFeaturesRotary: (state, getters): any => {
    const request: any = {
      series: state.valve.series || '',
      designStyle: state.valve.designStyle || '',
      flowDirection: state.valve.flowDirection,
      pressureClass: state.valve.pressureClass,
      packingMaterial: state.valve.packingMaterial,
      bearingMaterial: state.valve.bearingMaterial,
      seatType: state.valve.seatType,
      seatCode: state.valve.seatCode || '',
      seatAmount: state.valve.seatAmount,
      trimOption: state.valve.trimOption || '',
      trimCharacteristic: state.valve.trimCharacteristics,
      type: state.valve.seriesType || state.valve.type || ''
    };
    request.size = getters.valveSize;
    request.reduction = getters.valveSizeReduction;
    return request;
  },
  valveFeaturesLinear: (state, getters) => {
    const request: any = {
      series: state.valve.series || '',
      pressureClass: state.valve.pressureClass,
      trimPressureClass: undefined,
      trimOption: state.valve.trimOption,
      trimCharacteristic: state.valve.trimCharacteristics,
      flowDirection: state.valve.flowDirection,
      trimSize: undefined,
      packingMaterial: state.valve.packingMaterial,
      packingType: undefined,
      seatType: state.valve.seatType,
      trimType: state.valve.trimType,
      seatLeakage: state.valve.seatLeakage,
      bonnetType: state.valve.bonnetType,
      reduction: state.valve.sizeReduction,
      designStyle: state.valve.designStyle,
      type: state.valve.seriesType || state.valve.type || ''
    };
    request.size = getters.valveSize;
    request.trimReduction = Convert.toTrimReduction(getters.valveSizeReduction);
    return request;
  },
  valveFeaturesThirdParty: (state): any => {
    return {
      breakToOpen: state.valve.breakToOpen,
      runToOpen: state.valve.runToOpen,
      endToOpen: state.valve.endToOpen,
      breakToClose: state.valve.breakToClose,
      runToClose: state.valve.runToClose,
      endToClose: state.valve.endToClose,
      isThirdPartyValve: state.valve.isThirdPartyValve,
      thirdPartyValveType: state.valve.valveMotion
    };
  },
  actuatorFlowCases(state): any {
    const resultCases = get(state, ['result', 'valves', 0, 'flowCases']);

    if (!resultCases) {
      return [];
    }

    const dpByIndex = state.flowCases.cases.reduce((map, fc, idx) => {
      map.set(`${idx}`, fc.pressureDifference as number);
      return map;
    }, new Map<string, number>());

    return resultCases
      .map((fc: any, idx: number) => ({
        caseId: fc.caseId,
        travel: fc.travel,
        dpControl: dpByIndex.get(`${idx}`),
        dpControlUnit: state.flowCases.units.pressureDifferenceUnit
      }))
      .filter((fc: any) => {
        return typeof fc.travel === 'number' && typeof fc.dpControl === 'number';
      });
  },
  actuatorRequirements(state): any {
    const result: any = {};
    const safetyFactor = get(state, ['actuator', 'safetyFactor']);

    if (safetyFactor !== undefined) {
      result.actuatorSizingSafetyFactor = safetyFactor;
    }

    return result;
  },
  actuatorRotaryRequest(state, getters): any {
    const request: any = {
      sizingId: state.sizingId,
      processData: getters.actuatorProcessData,
      requirements: getters.actuatorRequirements,
      actuatorFeaturesRotary: getters.actuatorFeatures
    };

    if (state.valve.isThirdPartyValve) {
      request.valveFeaturesThirdParty = getters.valveFeaturesThirdParty;
    } else {
      request.valveFeaturesRotary = getters.valveFeaturesRotary;
    }

    if (getters.valveFunction === ValveFunction.Control || state.valve.isThirdPartyValve) {
      request.flowCases = state.valve.isThirdPartyValve
        ? getters.flowCases.filter((x: any) => x.runToClose && x.travel && x.runToOpen)
        : getters.actuatorFlowCases;
    }

    return request;
  },
  actuatorLinearRequest(state, getters): any {
    const request: any = {
      sizingId: state.sizingId,
      processData: getters.actuatorProcessData,
      requirements: getters.actuatorRequirements,
      actuatorFeaturesLinear: getters.actuatorFeatures
    };

    if (state.valve.isThirdPartyValve) {
      request.valveFeaturesThirdParty = getters.valveFeaturesThirdParty;
    } else {
      request.valveFeaturesLinear = getters.valveFeaturesLinear;
    }

    return request;
  },
  controlActuatorRotary: (_, getters): any => getters.actuatorRotaryRequest,
  controlActuatorLinear: (_, getters): any => getters.actuatorLinearRequest,
  hasValveResults: (state) => {
    return !!state.result?.hasResult;
  },
  hasActuatorResults: (state) => {
    return !!state.actuatorResult?.hasResult;
  }
};
export const mutations: MutationTree<SizingState> = {
  ...isEditedMutations,
  reload(state) {
    state.reload++;
  },
  processMedium_set(state, processMedium: ProcessMedium) {
    Object.assign(state.processMedium, processMedium);
  },
  processMedium_reset(state, defaultUnits: DefaultUnits) {
    Object.assign(state.processMedium, initialProcessMedium(defaultUnits));
  },
  processMedium_fluidType(state, value: FluidType) {
    state.processMedium.fluidType = value;
    switch (value) {
      case FluidType.Pulp:
        state.processMedium.liquidDensityType = LiquidDensityType.Gravity;
        state.processMedium.flowVelocityUnit = 'm/s';
        break;
      case FluidType.Liquid:
        state.processMedium.flowVelocityUnit = 'm/s';
        break;
      case FluidType.TwoPhase:
        state.processMedium.flowVelocityUnit = 'm/s';
        break;
      case FluidType.Gas:
        state.processMedium.flowVelocityUnit = 'Mach';
        break;
      default:
        state.processMedium.flowVelocityUnit = 'm/s';
    }
  },
  processMedium_liquid(state, value: string) {
    state.processMedium.liquid = value;
  },
  processMedium_gas(state, value: string) {
    state.processMedium.gas = value;
  },
  processMedium_description(state, value: string) {
    state.processMedium.description = value;
  },
  processMedium_liquidDensityType(state, value: string) {
    state.processMedium.liquidDensityType = value;
  },
  processMedium_liquidDensityType_reset(state) {
    state.processMedium.liquidDensityType = LiquidDensityType.Gravity;
  },
  units_liquidDensityUnit_reset(state) {
    state.flowCases.units.liquidDensityUnit = 'kg/m3';
  },
  processMedium_liquidSpecificGravity(state, value: number) {
    state.processMedium.liquidSpecificGravity = value;
  },
  processMedium_criticalPressureUnit(state, value: string) {
    state.processMedium.criticalPressureUnit = value;
  },
  processMedium_criticalPressureUnit_reset(state) {
    state.processMedium.criticalPressureUnit = 'atmA';
  },
  processMedium_criticalPressure(state, value: number) {
    state.processMedium.criticalPressure = value;
  },
  processMedium_pulpConsistency(state, value: number) {
    state.processMedium.pulpConsistency = value;
  },
  processMedium_pulpType(state, value: PulpType) {
    state.processMedium.pulpType = value;
  },
  processMedium_gasMassType_reset(state) {
    state.processMedium.gasMassType = GasMassType.MolecularWeight;
  },
  processMedium_gasMassType(state, value: GasMassType) {
    state.processMedium.gasMassType = value;
  },
  processMedium_gasSpecificGravity(state, value: number) {
    state.processMedium.gasSpecificGravity = value;
  },
  processMedium_gasMolecularWeight(state, value: number) {
    state.processMedium.gasMolecularWeight = value;
  },
  processMedium_flowVelocityUnit(state, value: string) {
    state.processMedium.flowVelocityUnit = value;
  },
  processMedium_capacityUnit(state, value: string) {
    state.processMedium.capacityUnit = value;
  },
  processMedium_ratioOfSpecificHeats(state, value: number) {
    state.processMedium.ratioOfSpecificHeats = value;
  },
  processMedium_inletTemperatureUnit(state, value: string) {
    state.processMedium.inletTemperatureUnit = value;
  },
  processMedium_minInletTemperature(state, value: number) {
    state.processMedium.minInletTemperature = value;
  },
  processMedium_maxInletTemperature(state, value: number) {
    state.processMedium.maxInletTemperature = value;
  },
  processMedium_phaseType(state, value: string) {
    state.processMedium.phaseType = value;
  },
  processMedium_supplyPressureUnit(state, value: string) {
    state.processMedium.supplyPressureUnit = value;
  },
  processMedium_supplyPressure(state, value: number) {
    state.processMedium.supplyPressure = value;
  },
  processMedium_shutoffPressureiDfferenceUnit(state, value: string) {
    state.processMedium.shutoffPressureiDfferenceUnit = value;
  },
  processMedium_shutoffPressureiDfference(state, value: number) {
    state.processMedium.shutoffPressureiDfference = value;
  },
  processMedium_designPressureUnit(state, value: string) {
    state.processMedium.designPressureUnit = value;
  },
  processMedium_designPressureMin(state, value: number) {
    state.processMedium.designPressureMin = value;
  },
  processMedium_designPressureMax(state, value: number) {
    state.processMedium.designPressureMax = value;
  },
  processMedium_designTemperatureUnit(state, value: string) {
    state.processMedium.designTemperatureUnit = value;
  },
  processMedium_designTemperatureMin(state, value: number) {
    state.processMedium.designTemperatureMin = value;
  },
  processMedium_designTemperatureMax(state, value: number) {
    state.processMedium.designTemperatureMax = value;
  },
  processMedium_torqueUnit(state, value: string) {
    state.processMedium.torqueUnit = value;
  },
  pipelineSizes_set(state, pipelineSizes: PipelineSizes) {
    Object.assign(state.pipelineSizes, pipelineSizes);
  },
  pipelineSizes_reset(state, defaultUnits: DefaultUnits) {
    Object.assign(state.pipelineSizes, initialPipelineSizes(defaultUnits));
  },
  pipelineSizes_unit(state, value: 'in' | 'mm') {
    state.pipelineSizes.unit = value || '';
  },
  pipelineSizes_unit_reset(state) {
    state.pipelineSizes.unit = 'mm';
  },
  pipelineSizes_inletSize(state, value: number | undefined) {
    state.pipelineSizes.inletSize = value;
  },
  pipelineSizes_outletSize(state, value: number | undefined) {
    state.pipelineSizes.outletSize = value;
  },
  pipelineSizes_schedule(state, value: string) {
    state.pipelineSizes.schedule = value;
  },
  pipelineSizes_thicknessType(state, value: ThicknessType) {
    state.pipelineSizes.thicknessType = value;
  },
  pipelineSizes_thickness(state, value: number | undefined) {
    state.pipelineSizes.thickness = value;
  },
  pipelineSizes_insulationType(state, value: string | undefined) {
    state.pipelineSizes.insulationType = value;
  },
  pipelineSizes_insulationThickness(state, value: number | undefined) {
    state.pipelineSizes.insulationThickness = value;
  },
  units_set(state, value: FlowCaseUnits) {
    Object.assign(state.flowCases.units, cloneDeep(value));
  },
  units_reset(state) {
    Object.assign(state.flowCases.units, initialFlowCaseUnits());
  },
  units_liquidFlowRateUnit(state, value: string) {
    state.flowCases.units.liquidFlowRateUnit = value;
  },
  units_gasFlowRateUnit(state, value: string) {
    state.flowCases.units.gasFlowRateUnit = value;
  },
  units_inletPressureUnit(state, value: string) {
    state.flowCases.units.inletPressureUnit = value;
    state.flowCases.units.pressureDifferenceUnit = value.substring(0, value.length - 1);
  },
  units_inletTemperatureUnit(state, value: string) {
    state.flowCases.units.inletTemperatureUnit = value;
  },
  units_inletDensityUnit(state, value: string) {
    state.flowCases.units.inletDensityUnit = value;
  },
  units_liquidDensityUnit(state, value: string) {
    state.flowCases.units.liquidDensityUnit = value;
  },
  units_pressureDifferenceUnit(state, value: string) {
    state.flowCases.units.pressureDifferenceUnit = value;
  },
  units_vapourPressureUnit(state, value: string) {
    state.flowCases.units.vapourPressureUnit = value;
  },
  units_kinematicViscosityUnit(state, value: string) {
    state.flowCases.units.kinematicViscosityUnit = value;
  },
  units_dynamicViscosityUnit(state, value: string) {
    state.flowCases.units.dynamicViscosityUnit = value;
  },
  units_viscosityType(state, value: string) {
    state.flowCases.units.viscosityType = value;
  },
  units_viscosityType_reset(state) {
    state.flowCases.units.viscosityType = ViscosityType.Dynamic;
  },
  flowCases_set(state, flowCases: FlowCase[]) {
    const flowCasePropertyWhiteList = [
      'caseId',
      'caseGuid',
      'inletTemperature',
      'inletPressure',
      'pressureDifference',
      'outletPressure',
      'liquidFlowRate',
      'vapourPressure',
      'gasFlowRate',
      'compressibility',
      'inletDensity',
      'liquidDensity',
      'kinematicViscosity',
      'dynamicViscosity',
      'travel',
      'runToClose',
      'runToOpen'
    ];

    flowCases.forEach((flowCase: any) => {
      Object.keys(flowCase).forEach((property) => {
        if (!flowCasePropertyWhiteList.includes(property)) {
          delete flowCase[property];
        }
      });
    });

    state.flowCases.maxCases = 4;
    state.flowCases.caseCount = 4;
    state.flowCases.cases.splice(0, state.flowCases.caseCount);
    state.flowCases.cases.push(...cloneDeep(flowCases));
  },
  flowCases_caseId(state, { index, value }) {
    if (typeof index === 'number') {
      state.flowCases.cases[index].caseId = value;
    }
  },
  flowCase_edit(
    state,
    {
      flowCase,
      caseIndex,
      propertiesToEdit
    }: {
      flowCase: FlowCase;
      caseIndex: number;
      propertiesToEdit: string[];
    }
  ) {
    const cases = cloneDeep(state.flowCases.cases);
    const editSource: any = cloneDeep(flowCase);
    const editTarget: any = cases[caseIndex];
    propertiesToEdit.forEach((propertyToEdit) => {
      editTarget[propertyToEdit] = editSource[propertyToEdit];
    });
    state.flowCases.cases = [...cases];
  },
  flowCase_copy(state, caseIndex: number) {
    const cases = cloneDeep(state.flowCases.cases);
    cases[caseIndex] = cloneDeep(cases[caseIndex - 1]);
    cases[caseIndex].caseId = caseIndex + 1 + '. Case';
    cases[caseIndex].caseGuid = state.flowCases.cases[caseIndex].caseGuid;
    state.flowCases.cases = [...cases];
  },
  flowCase_delete(state, caseIndex: number) {
    const cases = cloneDeep(state.flowCases.cases);
    cases[caseIndex] = emptyFlowCase(FluidType.Liquid, caseId(caseIndex));
    cases[caseIndex].caseId = caseIndex + 1 + '. Case';
    cases[caseIndex].caseGuid = state.flowCases.cases[caseIndex].caseGuid;
    state.flowCases.cases = [...cases];
  },
  flowCase_validity_set(state, { caseIndex, isValid }: { caseIndex: number; isValid: boolean }) {
    if (caseIndex >= 0 && caseIndex < state.flowCases.caseCount) {
      let validIndices = [...state.flowCases.validFlowCaseIndexes];

      if (isValid && !validIndices.includes(caseIndex)) {
        validIndices.push(caseIndex);
      } else if (!isValid && validIndices.includes(caseIndex)) {
        validIndices = validIndices.filter((i) => i !== caseIndex);
      }

      state.flowCases.validFlowCaseIndexes = validIndices;
    }
  },
  flowCases_updateViscosityType(state, viscosityType: ViscosityType) {
    state.flowCases.units.viscosityType = viscosityType;
  },
  flowCases_updateFluidType(state, fluidType: FluidType) {
    const _type = state.valve.isThirdPartyValve ? FluidType.ThirdPartyValve : fluidType;

    const emptyCase = emptyFlowCase(_type);
    const newFlowCases = state.flowCases.cases.map((flowCase) => {
      return cloneDeepExistingProperties(flowCase, emptyCase) as FlowCase;
    });
    state.flowCases.cases = [...newFlowCases];
  },
  flowCases_reset(state, defaultUnits: DefaultUnits) {
    state.flowCases.maxCases = 4;
    state.flowCases.caseCount = 4;
    state.flowCases.validFlowCaseIndexes = [];
    const flowCases = state.flowCases.cases;
    state.flowCases.cases.splice(0, state.flowCases.caseCount);
    flowCases.push(...emptyFlowCases(state.processMedium.fluidType, state.flowCases.caseCount));
    state.flowCases.units = initialFlowCaseUnits(defaultUnits);
  },
  flowCases_fill(state) {
    const maxCases = state.flowCases.maxCases;
    const flowCases = state.flowCases.cases;

    while (flowCases.length < maxCases) {
      flowCases.push(emptyFlowCase(state.processMedium.fluidType));
    }
  },
  flowCases_vapourPressure(state, { index, value }) {
    if (typeof index === 'number') {
      (state.flowCases.cases[index] as LiquidFlowCase).vapourPressure = value;
    }
  },
  flowCases_compressibility(state, { index, value }) {
    if (typeof index === 'number') {
      (state.flowCases.cases[index] as GasFlowCase).compressibility = value;
    }
  },
  flowCase_prop_set(state, { index, propName, value }) {
    if (typeof index !== 'number') {
      return;
    }

    const flowCase: any = state.flowCases.cases[index];

    if (flowCase[propName] !== 0 && !flowCase[propName]) {
      return;
    }

    flowCase[propName] = value;
  },
  valve_set(state, value: Valve) {
    Object.assign(state.valve, {
      ...value,
      valveMotion: value.series || value.isThirdPartyValve ? value.valveMotion : undefined,
      seriesType: value.series ? value.seriesType : undefined
    });
  },
  valve_reset(state) {
    Object.assign(state.valve, initialValve());
  },
  valve_details_reset(state) {
    let props = omit(initialValve(), [
      'pressureClass',
      'type',
      'series',
      'seriesType',
      'valveMotion',
      'size',
      'autoSize',
      'sizeReduction',
      'autoSizeReduction',
      'designStyle',
      'flowDirection',
      'seatCode',
      'trimCharacteristics',
      'trimOption'
    ]);

    if (state.actuator.active) {
      props = omit(props, ['seatType', 'packingMaterial']);

      if (state.valve.valveMotion === ValveMotion.Rotary) {
        props = omit(props, ['bearingMaterial', 'seatAmount']);
      }

      if (state.valve.valveMotion === ValveMotion.Linear) {
        props = omit(props, ['trimType', 'seatLeakage', 'bonnetType']);
      }
    }

    Object.assign(state.valve, props);
  },
  valve_valveMotion(state, value: ValveMotion) {
    state.valve.valveMotion = value;
  },
  valve_type(state, value: string) {
    state.valve.type = value;
  },
  valve_series(state, value: string) {
    state.valve.series = value;
  },
  valve_seriesType(state, value: string) {
    state.valve.seriesType = value;
  },
  valve_pressureClass(state, value: string) {
    state.valve.pressureClass = value;
  },
  valve_size(state, value: number) {
    state.valve.size = value;
  },
  valve_designStyle(state, value: string) {
    state.valve.designStyle = value;
  },
  valve_flowDirection(state, value: string) {
    state.valve.flowDirection = value;
  },
  valve_seatCode(state, value: string) {
    state.valve.seatCode = value;
  },
  valve_trimCharacteristics(state, value: string) {
    state.valve.trimCharacteristics = value;
  },
  valve_trimOption(state, value: string) {
    state.valve.trimOption = value;
  },
  valve_sizeReduction(state, value: number) {
    state.valve.sizeReduction = value;
  },
  valve_autoSize(state, size?: number) {
    state.valve.autoSize = size;
  },
  valve_autoSizeReduction(state, reduction?: number) {
    state.valve.autoSizeReduction = reduction;
  },
  valve_autoSize_reset(state) {
    state.valve.autoSize = undefined;
    state.valve.autoSizeReduction = undefined;
  },
  valve_seatType(state, value: string) {
    state.valve.seatType = value;
  },
  valve_bearingMaterial(state, value: string) {
    state.valve.bearingMaterial = value;
  },
  valve_packingMaterial(state, value: string) {
    state.valve.packingMaterial = value;
  },
  valve_seatAmount(state, value: number) {
    state.valve.seatAmount = value;
  },
  valve_trimType(state, value: string) {
    state.valve.trimType = value;
  },
  valve_seatLeakage(state, value: string) {
    state.valve.seatLeakage = value;
  },
  valve_bonnetType(state, value: string) {
    state.valve.bonnetType = value;
  },
  valve_is_third_party_valve_set(state, value: boolean) {
    state.valve.isThirdPartyValve = value;
  },
  valve_breakToOpen_set(state, value: number) {
    state.valve.breakToOpen = value;
  },
  valve_runToOpen_set(state, value: number) {
    state.valve.runToOpen = value;
  },
  valve_endToOpen_set(state, value: number) {
    state.valve.endToOpen = value;
  },
  valve_breakToClose_set(state, value: number) {
    state.valve.breakToClose = value;
  },
  valve_runToClose_set(state, value: number) {
    state.valve.runToClose = value;
  },
  valve_endToClose_set(state, value: number) {
    state.valve.endToClose = value;
  },
  tagId_set(state, value: string) {
    state.tagId = value;
  },
  tagId_reset(state) {
    state.tagId = undefined;
  },
  sizingId_set(state, value: string) {
    state.sizingId = value;
  },
  sizingId_reset(state) {
    state.sizingId = undefined;
  },
  deviceId_set(state, value: string) {
    state.deviceId = value;
  },
  deviceId_reset(state) {
    state.deviceId = undefined;
  },
  valveFunction_set(state, value: ValveFunction) {
    state.valveFunction = value || ValveFunction.Control;
  },
  valveFunction_reset(state) {
    state.valveFunction = ValveFunction.Control;
  },
  sizingName_set(state, value: string) {
    state.sizingName = value;
  },
  sizingName_reset(state) {
    state.sizingName = 'control';
  },
  internalComment_set(state, value: string) {
    state.comments.internalComment = value;
  },
  externalComment_set(state, value: string) {
    state.comments.externalComment = value;
  },
  comments_reset(state) {
    state.comments = {
      internalComment: undefined,
      externalComment: undefined
    };
  },
  result_set(state, value: ControlFlowResult) {
    state.result = Object.assign({}, value, {
      errorMessage: value.errorMessage || undefined
    });
  },
  result_reset(state) {
    state.result = null;
  },
  valve_series_reset(state) {
    state.valve.series = undefined;
    state.valve.seriesType = undefined;
    state.valve.valveMotion = undefined;
  },
  attenuator_active(state, value: boolean) {
    state.attenuator.active = value;
  },
  attenuator_type(state, value: string) {
    state.attenuator.type = value;
  },
  attenuator_size(state, value: number) {
    state.attenuator.size = value;
  },
  attenuator_pressureDifference(state, value: number) {
    state.attenuator.pressureDifference = value;
  },
  attenuator_set(state, value: Attenuator) {
    state.attenuator.active = !!value && value.active;

    if (value) {
      state.attenuator.type = value.type;
      state.attenuator.size = value.size;
      state.attenuator.pressureDifference = value.pressureDifference;
    }
  },
  attenuator_reset(state) {
    Object.assign(state.attenuator, initialAttenuator());
  },
  resistor_active(state, value: boolean) {
    state.resistor.active = value;
  },
  resistor_type(state, value: string) {
    state.resistor.type = value;
  },
  resistor_size(state, value: number) {
    state.resistor.size = value;
  },
  resistor_pressureDifference(state, value: number) {
    state.resistor.pressureDifference = value;
  },
  resistor_set(state, value: Resistor) {
    state.resistor.active = !!value && value.active;

    if (value) {
      state.resistor.type = value.type;
      state.resistor.size = value.size;
      state.resistor.pressureDifference = value.pressureDifference;
    }
  },
  resistor_reset(state) {
    Object.assign(state.resistor, initialResistor());
  },
  actuator_active(state, value: boolean) {
    state.actuator.active = value;
  },
  actuator_series(state, value: string) {
    state.actuator.series = value;
  },
  actuator_springAction(state, value: string) {
    state.actuator.springAction = value;
  },
  actuator_size(state, value: string) {
    state.actuator.size = value;
  },
  actuator_springRange(state, value: string) {
    state.actuator.springRange = value;
  },
  actuator_safetyFactor(state, value: number) {
    state.actuator.safetyFactor = value;
  },
  actuator_safetyFactorSelectionOption(state, value: SafetyFactorSelectionOption) {
    if (state.actuator.safetyFactorSelectionOption !== value && value === SafetyFactorSelectionOption.Automatic) {
      state.actuator.safetyFactor = undefined;
    }

    state.actuator.safetyFactorSelectionOption = value;
  },
  actuator_minimumSize(state, value: number) {
    state.actuator.minimumSize = value;
  },
  actuator_set(state, value: Actuator) {
    Object.assign(state.actuator, value);
  },
  actuator_details_reset(state) {
    Object.assign(state.actuator, omit(initialActuator(), ['active', 'series']));
  },
  actuator_reset(state) {
    Object.assign(state.actuator, initialActuator());
  },
  actuator_result_set(state, value: any) {
    state.actuatorResult = Object.assign({}, value);
  },
  actuator_result_reset(state) {
    state.actuatorResult = null;
  },
  isError(state, value: boolean) {
    state.isError = value;
  },
  stateAfterSizingLoaded_set(state, value: string) {
    state.stateAfterSizingLoaded = value;
  },
  stateAfterDropDownOptiosLoaded_set(state, value: string) {
    state.stateAfterDropDownOptiosLoaded = value;
  }
};

export const actions: ActionTree<SizingState, RootState> = {
  resetSizing({ commit, rootGetters }, { valveFunction } = {}) {
    const opportunity = rootGetters['opportunity/selectedOpportunity'];
    const defaultUnits = opportunity.defaultUnits;
    commit(mutations.tagId_reset.name);
    commit(mutations.sizingId_reset.name);
    commit(mutations.deviceId_reset.name);
    commit(mutations.comments_reset.name);
    commit(mutations.processMedium_reset.name, defaultUnits);
    commit(mutations.pipelineSizes_reset.name, defaultUnits);
    commit(mutations.valve_reset.name);
    commit(mutations.units_reset.name);
    commit(mutations.flowCases_reset.name, defaultUnits);
    commit(mutations.flowCases_fill.name);
    commit(mutations.reload.name);
    commit(mutations.sizingName_reset.name);
    commit(mutations.valveFunction_reset.name);
    commit(mutations.result_reset.name);
    commit(mutations.attenuator_reset.name);
    commit(mutations.resistor_reset.name);
    commit(mutations.actuator_reset.name);
    commit(mutations.actuator_result_reset.name);

    if (valveFunction === ValveFunction.OnOff) {
      commit(mutations.valveFunction_set.name, ValveFunction.OnOff);
      commit(mutations.sizingName_set.name, 'on-off');
      commit(mutations.actuator_active.name, true);
    }

    commit('notEdited');
  },
  changeFluidType({ commit }, { fluidType }) {
    commit(mutations.processMedium_fluidType.name, fluidType);
    commit(mutations.flowCases_updateFluidType.name, fluidType);
    commit(mutations.result_reset.name);
    commit(mutations.reload.name);
  },
  changeFlowVelocityUnit({ commit, dispatch }, unit) {
    commit(mutations.processMedium_flowVelocityUnit.name, unit);
    dispatch('calculateWithProgressIndicator');
  },
  changeCapacityUnit({ commit, dispatch }, unit) {
    commit(mutations.processMedium_capacityUnit.name, unit);
    dispatch('calculateWithProgressIndicator');
  },
  changeTorqueUnit({ commit, dispatch }, unit) {
    commit(mutations.processMedium_torqueUnit.name, unit);
    dispatch('calculateWithProgressIndicator');
  },
  editFlowCase(
    { commit, dispatch, getters },
    {
      flowCase,
      caseIndex,
      propertiesToEdit
    }: {
      flowCase: FlowCase;
      caseIndex: number;
      propertiesToEdit: string[];
    }
  ) {
    const editSource: any = flowCase;
    const editTarget: any = getters.cases[caseIndex];
    const actuallyEdited = propertiesToEdit.some(
      (propertyToEdit) => editTarget[propertyToEdit] !== editSource[propertyToEdit]
    );

    if (actuallyEdited) {
      commit('flowCase_edit', { flowCase, caseIndex, propertiesToEdit });
      commit('isEdited');
      commit('result_reset');
      commit('actuator_result_set');
      dispatch('chart/reset', null, { root: true });
    }
  },
  copyFlowCase({ commit, dispatch }, caseIndex) {
    commit(mutations.flowCase_copy.name, caseIndex);
    commit(mutations.reload.name);
    dispatch('chart/reset', null, { root: true });
  },
  deleteFlowCase({ commit, dispatch }, caseIndex) {
    commit(mutations.flowCase_delete.name, caseIndex);
    commit(mutations.reload.name);
    dispatch('chart/reset', null, { root: true });
  },
  loadWithProgressIndicator: createProgressIndicatorAction('loadWithoutDetectingEdits', AsyncOperation.LoadingSizing),
  loadWithoutDetectingEdits: createActionWithUndetectedEdit('loadSizing'),
  async loadSizing({ commit, dispatch, rootGetters, rootState }, { opportunityId, sizingId }) {
    commit(mutations.isError.name, false);
    const selectedVersion = rootState.project?.selectedVersion;
    const sizing = await sizingService
      .read(selectedVersion?.projectId ?? '', selectedVersion?.id ?? '', opportunityId, sizingId)
      .catch(() => {
        commit(mutations.sizingId_set.name, sizingId);
        commit(mutations.isError.name, true);
      });

    if (!sizing) {
      return;
    }

    const {
      tagId,
      sizingName,
      sizingComment,
      internalComment,
      valveFunction,
      selectedDeviceId: deviceId,
      process,
      devices: [device]
    } = sizing;

    await dispatch('resetSizing', {
      valveFunction: valveFunction
    });
    await dispatch('units/reset', null, { root: true });

    if (tagId) {
      commit(mutations.tagId_set.name, tagId);
    }

    if (sizingId) {
      commit(mutations.sizingId_set.name, sizingId);
    }

    if (deviceId) {
      commit(mutations.deviceId_set.name, deviceId);
    }

    if (sizingName) {
      commit(mutations.sizingName_set.name, sizingName);
    }

    if (sizingComment) {
      commit(mutations.externalComment_set.name, sizingComment);
    }

    if (internalComment) {
      commit(mutations.internalComment_set.name, internalComment);
    }

    commit(mutations.valveFunction_set.name, valveFunction);
    await dispatch('chart/reset', null, { root: true });

    if (!isEmpty(process)) {
      const { processMedium, pipelineSizes, flowCaseUnits, flowCases } = process;

      if (!isEmpty(processMedium)) {
        commit(mutations.processMedium_set.name, processMedium);
      }

      if (!isEmpty(pipelineSizes)) {
        if (!pipelineSizes.thicknessType || pipelineSizes.thicknessType === '') {
          if (!pipelineSizes.thickness || pipelineSizes.thickness === '') {
            pipelineSizes.thicknessType = ThicknessType.Schedule;
          } else {
            pipelineSizes.thicknessType = ThicknessType.Thickness;
          }
        }

        commit(mutations.pipelineSizes_set.name, pipelineSizes);
      }

      await dispatch('units/pipeSizes', true, { root: true });

      if (!isEmpty(flowCaseUnits)) {
        commit(mutations.units_set.name, flowCaseUnits);
      }

      if (!isEmpty(flowCases)) {
        commit(mutations.flowCases_set.name, flowCases);
        commit(mutations.flowCases_fill.name, flowCases);
      }
    }

    commit(mutations.valve_reset.name);
    commit(mutations.result_reset.name);

    if (!isEmpty(device)) {
      if (!isEmpty(device.device)) {
        const devices = device.device;

        if (!isEmpty(devices.valve)) {
          commit(mutations.valve_set.name, devices.valve);
        }

        if (!isEmpty(devices.attenuator)) {
          commit(mutations.attenuator_set.name, {
            ...devices.attenuator,
            active: true
          });
        }

        if (!isEmpty(devices.resistor)) {
          commit(mutations.resistor_set.name, {
            ...devices.resistor,
            active: true
          });
        }

        if (!isEmpty(devices.actuator)) {
          commit(mutations.actuator_reset.name);
          commit(mutations.actuator_set.name, {
            ...devices.actuator,
            active: true
          });
        }
      }

      await dispatch('units/setFluidTypes', true, {
        root: true
      });

      if (!isEmpty(device.result)) {
        const result = device.result.result;

        if (result) {
          //results check for old sizings wihtout hasResult
          if (!result.hasResult && result.valves) {
            result.hasResult = hasNonEmptyFlowCaseResults(result.valves[0]?.flowCases);
          }

          commit(mutations.result_set.name, result);
        }
      }

      if (!isEmpty(device.actuatorResult)) {
        const actuatorResult = device.actuatorResult.actuatorResult;

        if (!isEmpty(actuatorResult)) {
          //results check for old sizings wihtout hasResult
          if (!actuatorResult.hasResult && (actuatorResult.actuator?.resultStatus || actuatorResult.errorMessage)) {
            actuatorResult.hasResult = true;
          }

          commit(mutations.actuator_result_set.name, actuatorResult);
        }
      }
    }

    await dispatch('takeSnapshotFromStateAfterSizingLoaded');

    await dispatch('units/fetchInsulationTypes', true, { root: true });

    if (isEmpty(state.valve.valveMotion) && !isEmpty(state.valve.series)) {
      const valveMotion = rootGetters['units/valveEntryBySeries'](state.valve.series);
      await dispatch('updateTorqueUnitOnValveMotionChange', valveMotion ? valveMotion.motion : undefined);
      commit(mutations.valve_valveMotion.name, valveMotion ? valveMotion.motion : undefined);
    }

    if (isEmpty(state.processMedium.fluidType)) {
      commit(mutations.processMedium_fluidType.name, FluidType.Liquid);
    }

    commit(mutations.reload.name);

    if (!isEmpty(state.valve.pressureClass) && !isEmpty(state.valve.series)) {
      await dispatch('units/fetchAllValveProperties', true, {
        root: true
      });
    }

    await dispatch('units/actuatorSeries', true, {
      root: true
    });

    if (!isEmpty(state.actuator.series)) {
      await dispatch('units/actuatorFetchAll', true, {
        root: true
      });
    }

    await dispatch('calculateVapourPressuresIfNotSet');

    commit('notEdited');
  },
  async takeSnapshotFromStateAfterSizingLoaded({ commit }) {
    const clonedState = getClonedSizingState();
    commit(mutations.stateAfterSizingLoaded_set.name, clonedState);
  },
  async takeSnapshotFromStateAfterDropDownOptiosLoaded({ commit }) {
    const clonedState = getClonedSizingState();
    commit('editing_disableAutoDetect');
    commit(mutations.stateAfterDropDownOptiosLoaded_set.name, clonedState);
    commit('editing_enableAutoDetect');
  },
  async calculateVapourPressuresIfNotSet({ dispatch }) {
    if (state.processMedium.fluidType === 'Liquid' || state.processMedium.fluidType === 'TwoPhase') {
      for (let i = 0; i < state.flowCases.cases.length; i++) {
        const currentCase = state.flowCases.cases[i] as LiquidFlowCase;

        if (
          currentCase !== undefined &&
          typeof currentCase.vapourPressure !== 'number' &&
          typeof currentCase.inletTemperature === 'number'
        ) {
          currentCase.vapourPressure = await dispatch('updateVapourPressure', {
            flowCase: currentCase,
            caseIndex: currentCase.caseId
          });
        }
      }
    }
  },
  async updateVapourPressure(
    { state, commit },
    { flowCase, caseIndex }: { flowCase: LiquidFlowCase | undefined; caseIndex: number }
  ) {
    if (flowCase !== undefined && !isEmpty(state.processMedium.liquid)) {
      const result = await calculationService.calculateVapourPressure({
        temperature: flowCase.inletTemperature,
        temperatureUnit: state.flowCases.units.inletTemperatureUnit,
        vapourPressureUnit: state.flowCases.units.vapourPressureUnit,
        liquid: state.processMedium.liquid
      });
      commit(mutations.flowCases_vapourPressure.name, {
        index: caseIndex,
        value: result
      });
      return result;
    }
  },
  async updateCompressibility(
    { state, commit },
    { flowCase, caseIndex }: { flowCase: GasFlowCase | undefined; caseIndex: number }
  ) {
    if (flowCase !== undefined) {
      const result = await calculationService.calculateCompressibility(
        state.processMedium.gas
          ? {
              temperature: flowCase.inletTemperature,
              temperatureUnit: state.flowCases.units.inletTemperatureUnit,
              pressure: flowCase.inletPressure,
              pressureUnit: state.flowCases.units.inletPressureUnit,
              gas: state.processMedium.gas
            }
          : {
              temperature: flowCase.inletTemperature,
              temperatureUnit: state.flowCases.units.inletTemperatureUnit,
              pressure: flowCase.inletPressure,
              pressureUnit: state.flowCases.units.inletPressureUnit
            }
      );
      commit(mutations.flowCases_compressibility.name, {
        index: caseIndex,
        value: result
      });
      return result;
    }
  },
  async createSizing({ state, getters, rootState, dispatch }) {
    const selectedVersion = rootState.project?.selectedVersion;
    const response = await sizingService.create(
      selectedVersion?.projectId ?? '',
      selectedVersion?.id ?? '',
      state.tagId as string,
      state.sizingName as string,
      state.comments,
      getters.processData,
      state.valveFunction as string
    );

    await dispatch('project/updateVersionHistoryIfNeeded', response.currentVersion, {
      root: true
    });

    return response.content;
  },
  async createNewSizing({ dispatch, commit }, tag: Tag) {
    await dispatch('resetSizing', {
      valveFunction: tag.tagType ?? ''
    });
    await dispatch('units/reset', null, { root: true });
    const tagId = tag.tagId ?? '';

    commit('tagId_set', tagId);
    const sizingId = await dispatch('saveWithProgressIndicator');
    commit(mutations.sizingId_set.name, sizingId);
  },
  async updateSizing({ state, getters, rootState, dispatch }) {
    const selectedVersion = rootState.project?.selectedVersion;
    const device: any = getters.deviceData;
    const result: any = getters.resultData;
    const actuatorResult: any = getters.actuatorResultData;
    const deviceData = {
      device,
      result,
      actuatorResult
    };

    if (!state.deviceId) {
      throw new Error('Missing deviceId');
    }

    const response = await sizingService.update(
      selectedVersion?.projectId ?? '',
      selectedVersion?.id ?? '',
      state.sizingId as string,
      state.deviceId,
      state.tagId as string,
      state.sizingName as string,
      state.comments,
      getters.processData,
      deviceData
    );

    await dispatch('project/updateVersionHistoryIfNeeded', response.currentVersion, {
      root: true
    });

    return response.content;
  },
  duplicateSizingWithProgressIndicator: createProgressIndicatorAction(
    'duplicateSizing',
    AsyncOperation.DuplicatingSizing
  ),
  async duplicateSizing({ dispatch, rootState }, { tagId, sizingId }) {
    const selectedVersion = rootState.project?.selectedVersion;
    const response = await sizingService.copy(
      selectedVersion?.projectId ?? '',
      selectedVersion?.id ?? '',
      tagId,
      sizingId
    );

    await dispatch('project/updateVersionHistoryIfNeeded', response.currentVersion, {
      root: true
    });

    await dispatch('sizingTree/loadTree', true, {
      root: true
    });
  },
  saveWithProgressIndicator: createProgressIndicatorAction('saveWithoutDetectingEdits', AsyncOperation.SavingSizing),
  saveWithoutDetectingEdits: createActionWithUndetectedEdit('save'),
  async save({ state, dispatch, commit, rootState }) {
    const isCreate = !state.sizingId;

    const { tagId, sizingId, selectedDeviceId, sizingName, valveFunction } = isCreate
      ? await dispatch('createSizing')
      : await dispatch('updateSizing');

    if (tagId) {
      commit(mutations.tagId_set.name, tagId);
    }

    if (sizingId) {
      commit(mutations.sizingId_set.name, sizingId);
    }

    if (selectedDeviceId) {
      commit(mutations.deviceId_set.name, selectedDeviceId);
    }

    if (sizingName) {
      commit(mutations.sizingName_set.name, sizingName);
    }

    if (valveFunction) {
      commit(mutations.valveFunction_set.name, valveFunction);
    }

    const chartData = rootState.chart.chartData;

    if (chartData === null) {
      await dispatch('chart/deleteChartDataIfCleared', sizingId, {
        root: true
      });
    } else {
      await dispatch('chart/saveChartData', null, { root: true });
    }

    commit(mutations.reload.name);
    await dispatch('sizingTree/loadTree', true, {
      root: true
    });

    commit('notEdited');

    return isCreate ? sizingId : undefined;
  },
  deleteSizingWithProgressIndicator: createProgressIndicatorAction('deleteSizing', AsyncOperation.DeletingSizing),
  async deleteSizing({ state, commit, dispatch, rootState }, sizingId) {
    if (state.sizingId === sizingId) {
      commit('notEdited');
    }

    const selectedVersion = rootState.project?.selectedVersion;
    const response = await sizingService.delete(selectedVersion?.projectId ?? '', selectedVersion?.id ?? '', sizingId);

    if (state.sizingId === sizingId) {
      await dispatch('resetSizing');
      await dispatch('units/reset', null, { root: true });
    }

    await dispatch('project/updateVersionHistoryIfNeeded', response.currentVersion, {
      root: true
    });

    await dispatch('sizingTree/loadTree', true, {
      root: true
    });
  },
  calculateWithProgressIndicator: createProgressIndicatorAction(
    'calculateWithoutDetectingEdits',
    AsyncOperation.Calculating
  ),
  calculateWithoutDetectingEdits: createActionWithUndetectedEdit('calculate'),
  async calculate({ dispatch, commit, getters, state }) {
    commit(mutations.result_reset.name);
    commit(mutations.actuator_result_reset.name);
    commit(mutations.valve_autoSize_reset.name);
    await dispatch('chart/reset', null, { root: true });

    const valveFunction: ValveFunction = getters.valveFunction;

    if (valveFunction === ValveFunction.Control && !state.valve.isThirdPartyValve) {
      const result = await calculationService.calculateValve(getters.controlflowRequest);

      if (result === undefined) {
        return;
      }

      if (result.valves && result.valves.length > 0) {
        const valve = get(result, ['valves', 0]);

        if (getters.isAutomaticSize) {
          commit(mutations.valve_autoSize.name, valve.size);
        }

        if (getters.isAutomaticReduction) {
          commit(mutations.valve_autoSizeReduction.name, valve.reduction);
        }

        valve.flowCases = fillSkippedFlowCases(getters.caseCount, valve.flowCases);
      } else if (!isEmpty(result.errorMessage)) {
        commit(
          'error/SET_ERROR',
          {
            alertType: '',
            error: result.errorMessage
          },
          { root: true }
        );
        return;
      }

      if (result.resultUnits?.flowVelocityUnit !== undefined) {
        commit(mutations.processMedium_flowVelocityUnit.name, result.resultUnits?.flowVelocityUnit);
      }

      if (result.resultUnits?.capacityUnit !== undefined) {
        commit(mutations.processMedium_capacityUnit.name, result.resultUnits?.capacityUnit);
      }

      commit(mutations.result_set.name, result);
    }

    if ((getters.actuator_active && getters.valveMotion !== ValveMotion.None) || state.valve.isThirdPartyValve) {
      const isRotary = getters.valveMotion === ValveMotion.Rotary;
      const request = isRotary ? getters.controlActuatorRotary : getters.controlActuatorLinear;
      const result =
        valveFunction === ValveFunction.Control
          ? await calculationService.calculateActuator(request, isRotary)
          : await calculationService.calculateOnOff(request, isRotary);

      if (result?.torqueUnit !== undefined) {
        commit(mutations.processMedium_torqueUnit.name, result?.torqueUnit);
      }

      commit(mutations.actuator_result_set.name, result);
    }

    await dispatch('chart/createChartData', null, { root: true });
    commit('isEdited');
  },
  async cpqExcelExport({ rootState }, sizingIds: string[]) {
    const selectedVersion = rootState.project?.selectedVersion;
    const request = {
      projectId: selectedVersion?.projectId,
      versionId: selectedVersion?.id,
      sizingIds: sizingIds
    };

    await exportService.downloadCpqExcel(request);
  },
  async standardExcelExport({ rootState }, sizingIds: string[]) {
    const selectedVersion = rootState.project?.selectedVersion;
    const request = {
      projectId: selectedVersion?.projectId,
      versionId: selectedVersion?.id,
      sizingIds: sizingIds
    };

    await exportService.downloadStandardExcel(request);
  },
  async sizingProjectExport({ rootGetters, rootState }, { sizingIds, silIds }) {
    const selectedVersion = rootState.project?.selectedVersion;
    const rootOpportunity = rootGetters['sizingTree/rootOpportunity'] as Opportunity;
    const areaIds: string[] = [];
    const tagIds: string[] = [];

    rootOpportunity?.areas?.forEach((area) => {
      area?.tags?.forEach((tag) => {
        tag?.sizings?.forEach((sizing) => {
          if (sizingIds.indexOf(sizing.sizingId) !== -1) {
            if (area?.areaId !== undefined && areaIds.indexOf(area.areaId) === -1) {
              areaIds.push(area.areaId);
            }

            if (tag?.tagId !== undefined && tagIds.indexOf(tag.tagId) === -1) {
              tagIds.push(tag.tagId);
            }
          }
        });
        tag?.sils?.forEach((sil) => {
          if (silIds.indexOf(sil.silId) !== -1) {
            if (area?.areaId !== undefined && areaIds.indexOf(area.areaId) === -1) {
              areaIds.push(area.areaId);
            }

            if (tag?.tagId !== undefined && tagIds.indexOf(tag.tagId) === -1) {
              tagIds.push(tag.tagId);
            }
          }
        });
      });
    });

    const request = {
      projectId: selectedVersion?.projectId,
      versionId: selectedVersion?.id,
      areaIds: areaIds,
      tagIds: tagIds,
      sizingIds: sizingIds,
      silIds: silIds
    };

    await exportService.downloadSizingProject(request);
  },
  async updateTorqueUnitOnValveMotionChange({ commit, state, rootGetters }, targetMotion) {
    if (state.valve.valveMotion !== targetMotion) {
      const defaultTorqueUnit = rootGetters['opportunity/selectedOpportunity']?.defaultUnits?.torqueUnit;
      let torqueUnit;

      if (defaultTorqueUnit == 'ftlb') {
        torqueUnit =
          targetMotion === ValveMotion.Linear ? 'lbf' : targetMotion === ValveMotion.Rotary ? 'ftlb' : undefined;
      } else {
        torqueUnit = targetMotion === ValveMotion.Linear ? 'N' : targetMotion === ValveMotion.Rotary ? 'Nm' : undefined;
      }

      commit('processMedium_torqueUnit', torqueUnit);
    }
  },
  async pasteExcelData({ commit, getters }, data) {
    const valveFunction = getters.valveFunction;
    const fluidType = getters.fluidType;
    const processMedium = getters.processMedium;
    const flowCaseUnits = getters.units;

    if (valveFunction !== ValveFunction.Control) {
      return; // no flowcases on onOff
    }

    const flowCases: FlowCase[] = [];

    const isKinematic = flowCaseUnits.viscosityType === ViscosityType.Kinematic;
    const isLiquidDensity = processMedium.liquidDensityType === LiquidDensityType.Density;
    const isGasInletDensity = processMedium.gasMassType === GasMassType.InletDensity;

    for (let i = 0; i < 4; i++) {
      const caseId = `Case`;

      switch (fluidType) {
        case FluidType.Liquid: {
          flowCases[i] = {
            caseId: caseId,
            caseGuid: state.flowCases.cases[i].caseGuid,
            liquidFlowRate: data[i]?.[0],
            inletTemperature: data[i]?.[1],
            inletPressure: data[i]?.[2],
            pressureDifference: data[i]?.[3],
            outletPressure: data[i]?.[4],
            vapourPressure: data[i]?.[5],
            kinematicViscosity: isKinematic ? data[i]?.[6] : undefined,
            dynamicViscosity: !isKinematic ? data[i]?.[6] : undefined,
            liquidDensity: isLiquidDensity ? data[i]?.[7] : undefined
          } as LiquidFlowCase;
          break;
        }
        case FluidType.Pulp: {
          flowCases[i] = {
            caseId: caseId,
            caseGuid: state.flowCases.cases[i].caseGuid,
            liquidFlowRate: data[i]?.[0],
            inletTemperature: data[i]?.[1],
            inletPressure: data[i]?.[2],
            pressureDifference: data[i]?.[3],
            outletPressure: data[i]?.[4]
          } as FlowCase;
          break;
        }
        case FluidType.Gas: {
          flowCases[i] = {
            caseId: caseId,
            caseGuid: state.flowCases.cases[i].caseGuid,
            gasFlowRate: data[i]?.[0],
            inletTemperature: data[i]?.[1],
            inletPressure: data[i]?.[2],
            pressureDifference: data[i]?.[3],
            outletPressure: data[i]?.[4],
            compressibility: !isGasInletDensity ? data[i]?.[5] : undefined,
            inletDensity: isGasInletDensity ? data[i]?.[5] : undefined
          } as FlowCase;
          break;
        }
        case FluidType.TwoPhase: {
          flowCases[i] = {
            caseId: caseId,
            caseGuid: state.flowCases.cases[i].caseGuid,
            gasFlowRate: data[i]?.[0],
            liquidFlowRate: data[i]?.[1],
            inletTemperature: data[i]?.[2],
            inletPressure: data[i]?.[3],
            pressureDifference: data[i]?.[4],
            outletPressure: data[i]?.[5],
            vapourPressure: data[i]?.[6],
            compressibility: data[i]?.[7],
            liquidDensity: isLiquidDensity ? data[i]?.[8] : undefined
          } as TwoPhaseFlowCase;
          break;
        }
        case FluidType.None: {
          flowCases[i] = {
            caseId: caseId,
            caseGuid: state.flowCases.cases[i].caseGuid,
            inletTemperature: data[i]?.[0],
            inletPressure: data[i]?.[1],
            pressureDifference: data[i]?.[2],
            outletPressure: data[i]?.[3]
          } as FlowCase;
          break;
        }
        case FluidType.ThirdPartyValve: {
          flowCases[i] = {
            caseId: caseId,
            caseGuid: state.flowCases.cases[i].caseGuid,
            travel: data[i]?.[0],
            runToOpen: data[i]?.[1],
            runToClose: data[i]?.[2]
          } as ThirdPartyValveFlowCase;
          break;
        }
        default:
          throw Error(`Not implemented: ${fluidType}`);
      }
    }

    commit('flowCases_set', flowCases);
    commit('reload');
  },
  changePipelineSizeUnit({ state, commit, rootGetters }, targetUnit: 'in' | 'mm') {
    if (targetUnit === state.pipelineSizes.unit) {
      return;
    }

    commit('pipelineSizes_unit', targetUnit);
    const converter = rootGetters['units/convertPipeSizeValueToDifferentUnit'];
    commit('pipelineSizes_inletSize', converter(targetUnit, state.pipelineSizes.inletSize));
    commit('pipelineSizes_outletSize', converter(targetUnit, state.pipelineSizes.outletSize));

    if (state.pipelineSizes.thicknessType === ThicknessType.Schedule) {
      commit('pipelineSizes_thickness', undefined);
    }
  }
};
export const sizing: Module<SizingState, RootState> = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
};

function getClonedSizingState(): string {
  const clonedState: any = cloneDeep(state);
  delete clonedState.editingAutoDetectDisabledCount;
  delete clonedState.flowCases.validFlowCaseIndexes;
  delete clonedState.isEdited;
  delete clonedState.isEditingAutoDetected;
  delete clonedState.reload;
  delete clonedState.stateAfterDropDownOptiosLoaded;
  delete clonedState.stateAfterSizingLoaded;
  return JSON.stringify(clonedState);
}

function emptyFlowCaseResult(caseId?: string): FlowCaseResult {
  return {
    caseId: caseId,
    caseGuid: undefined,
    reqCapacity: undefined,
    travel: undefined,
    openingDeg: undefined,
    noise: undefined,
    noiseDisplayValue: undefined,
    valveOutletVelocity: undefined,
    pipeOutletVelocity: undefined,
    terminalDp: undefined,
    xtCoeff: undefined,
    flCoeff: undefined,
    effectiveDensity: undefined,
    status: {
      notifications: [],
      statusCode: undefined
    },
    resistors: undefined,
    frequencySpectrum: undefined,
    diffuserDiameter: undefined,
    sigma: undefined
  };
}

function fillSkippedFlowCases(caseCount: number, originalResults: FlowCaseResult[]): FlowCaseResult[] {
  const flowCases: FlowCaseResult[] = [];

  for (let i = 0; i < caseCount; i++) {
    const caseGuid = state.flowCases?.cases[i]?.caseGuid;
    const flowCaseInResults = originalResults.find((f) => caseGuid === get(f, ['caseGuid'], ''));

    if (flowCaseInResults !== undefined) {
      flowCases.push(flowCaseInResults);
    } else {
      flowCases.push(emptyFlowCaseResult(caseId(i + 1)));
    }
  }

  return flowCases;
}

function intialFlowCases(): FlowCases {
  return {
    maxCases: 4,
    caseCount: 4,
    units: initialFlowCaseUnits(),
    validFlowCaseIndexes: [],
    cases: emptyFlowCases(FluidType.Liquid, 4)
  };
}

function initialFlowCaseUnits(defaultUnits?: DefaultUnits): FlowCaseUnits {
  const inletPressure = defaultUnits?.flowcasePressureUnit ?? 'barG';

  return {
    liquidFlowRateUnit: defaultUnits?.liquidFlowRateUnit ?? 'kg/h',
    inletTemperatureUnit: defaultUnits?.temperatureUnit ?? 'degC',
    inletPressureUnit: inletPressure,
    pressureDifferenceUnit: inletPressure.substring(0, inletPressure.length - 1),
    vapourPressureUnit: defaultUnits?.vapourPressureUnit ?? 'barG',
    gasFlowRateUnit: defaultUnits?.gasFlowRateUnit ?? 'kg/h',
    inletDensityUnit: defaultUnits?.gasDensityUnit ?? 'kg/dm3',
    liquidDensityUnit: defaultUnits?.liquidDensityUnit ?? 'kg/m3',
    kinematicViscosityUnit: defaultUnits?.kinematicViscosityUnit ?? 'm2/s',
    dynamicViscosityUnit: defaultUnits?.dynamicViscosityUnit ?? 'cp',
    viscosityType: ViscosityType.Dynamic
  };
}

function caseId(order: number = 1): string {
  return `${order}. Case`;
}

function emptyFlowCases(fluidType: FluidType, count: number): FlowCase[] {
  const flowCases = [];

  for (let i = 1; i <= count; i++) {
    flowCases.push(emptyFlowCase(fluidType, caseId(i), guid()));
  }

  return flowCases;
}

function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
}

function initialProcessMedium(defaultUnits?: DefaultUnits): ProcessMedium {
  return {
    fluidType: FluidType.Liquid,
    liquid: undefined,
    gas: undefined,
    description: undefined,
    liquidDensityType: LiquidDensityType.Gravity,
    liquidDensityUnit: defaultUnits?.liquidDensityUnit ?? 'kg/m3',
    liquidDensity: undefined,
    liquidSpecificGravity: undefined,
    criticalPressureUnit: defaultUnits?.criticalPressureUnit ?? 'barG',
    criticalPressure: undefined,
    pulpConsistency: undefined,
    pulpType: undefined,
    gasMassType: GasMassType.MolecularWeight,
    gasMolecularWeight: undefined,
    gasSpecificGravity: undefined,
    ratioOfSpecificHeats: undefined,
    inletTemperatureUnit: defaultUnits?.temperatureUnit ?? 'degC',
    minInletTemperature: undefined,
    maxInletTemperature: undefined,
    flowVelocityUnit: defaultUnits?.flowVelocityUnit ?? 'm/s',
    capacityUnit: defaultUnits?.capacityUnit ?? 'FpCv',
    phaseType: 'Normal',
    supplyPressureUnit: defaultUnits?.flowcasePressureUnit ?? 'barG',
    supplyPressure: 4.2,
    shutoffPressureiDfferenceUnit: defaultUnits?.shutoffPressureUnit ?? 'bar',
    shutoffPressureiDfference: undefined,
    torqueUnit: defaultUnits?.torqueUnit ?? 'Nm',
    designPressureMax: undefined,
    designPressureMin: undefined,
    designPressureUnit: defaultUnits?.designPressureUnit ?? 'barG',
    designTemperatureMax: undefined,
    designTemperatureMin: undefined,
    designTemperatureUnit: defaultUnits?.temperatureUnit ?? 'degC'
  };
}

function initialPipelineSizes(defaultUnits?: DefaultUnits): PipelineSizes {
  return {
    unit: defaultUnits?.pipelineSizeUnit === 'in' ? 'in' : 'mm',
    inletSize: undefined,
    outletSize: undefined,
    thicknessType: ThicknessType.Schedule,
    schedule: 'ScheduleStd',
    thickness: undefined,
    insulationThickness: undefined,
    insulationType: undefined
  };
}

function initialValve(): Valve {
  return {
    valveMotion: undefined,
    type: undefined,
    series: undefined,
    seriesType: undefined,
    pressureClass: undefined,
    size: undefined,
    autoSize: undefined,
    designStyle: undefined,
    flowDirection: undefined,
    seatCode: undefined,
    trimCharacteristics: undefined,
    trimOption: undefined,
    sizeReduction: undefined,
    autoSizeReduction: undefined,
    seatType: undefined,
    bearingMaterial: undefined,
    packingMaterial: undefined,
    seatAmount: undefined,
    trimType: undefined,
    seatLeakage: undefined,
    bonnetType: undefined,
    isThirdPartyValve: false,
    breakToOpen: undefined,
    runToOpen: undefined,
    endToOpen: undefined,
    breakToClose: undefined,
    runToClose: undefined,
    endToClose: undefined
  };
}

function initialAttenuator(): Attenuator {
  return {
    active: false,
    type: undefined,
    size: undefined,
    pressureDifference: undefined
  };
}

function initialResistor(): Resistor {
  return {
    ...initialAttenuator(),
    pressureDifference: undefined
  };
}

function initialActuator(): Actuator {
  return {
    active: false,
    series: undefined,
    springAction: undefined,
    size: undefined,
    springRange: undefined,
    safetyFactor: undefined,
    safetyFactorSelectionOption: SafetyFactorSelectionOption.Automatic,
    minimumSize: undefined
  };
}
