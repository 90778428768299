import Axios, { AxiosStatic } from 'axios';
import { get, pick, isEmpty } from 'lodash';
import { ControlFlowResult, SilCalculationRequest, SilResult } from '@/types';
import { VapourPressureRequest } from '@/types/request/VapourPressureRequest';
import { CompressibilityRequest } from '@/types/request/CompressibilityRequest';
import {
  GasFlowrateUnitConversion,
  LiquidFlowrateUnitConversion,
  UnitConversionRequest
} from '@/types/request/UnitConversionRequest';
import { CrudResponse } from '@/types/response/crudResponse';

class CalculationService {
  private http: AxiosStatic;

  constructor(axios: AxiosStatic) {
    this.http = axios;
  }

  async calculateValve(request: any): Promise<ControlFlowResult | undefined> {
    try {
      const response = await this.http.post('/calculate/controlflow', request);
      return response.data;
    } catch (err) {
      return undefined;
    }
  }

  async massCalculate(
    projectId: string,
    versionId: string,
    sizingId: string,
    calculateCharts: boolean
  ): Promise<CrudResponse> {
    const url = `/masscalculate/valveandactuator/${projectId}/${versionId}/${sizingId}?calculateCharts=${calculateCharts}`;
    const response = await this.http.post(url);
    return response.data;
  }

  async createChartData(
    projectId: string,
    versionId: string,
    sizingId: string,
    request: any
  ): Promise<ControlFlowResult> {
    const response = await this.http.post(`/chart/chartData/${projectId}/${versionId}/${sizingId}`, request);
    return response.data;
  }

  async saveChartData(
    projectId: string,
    versionId: string,
    sizingId: string,
    request: any
  ): Promise<ControlFlowResult> {
    const response = await this.http.post(`/chart/saveChartData/${projectId}/${versionId}/${sizingId}`, request);
    return response.data;
  }

  async getChartData(
    projectId: string,
    versionId: string,
    opportunityId: string,
    sizingId: string
  ): Promise<ControlFlowResult | undefined> {
    try {
      const url =
        !isEmpty(projectId) && !isEmpty(versionId)
          ? `/chart/chartData/${projectId}/${versionId}/${sizingId}`
          : `/chart/chartData/${opportunityId}/${sizingId}`;
      const response = await this.http.get(url);
      return response.data;
    } catch (err) {
      return undefined;
    }
  }

  async exportChartData(chartType: string, request: any): Promise<any> {
    const url = `/chart/exportChartData/${chartType}`;
    const response = await this.http.post(url, request, { responseType: 'blob' });
    const blob = new Blob([response.data], { type: response.headers['content-type'] });
    const csvUrl = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = csvUrl;
    link.download = chartType + '.csv';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    URL.revokeObjectURL(csvUrl);
  }

  async deleteChartData(projectId: string, versionId: string, sizingId: string): Promise<void> {
    try {
      await this.http.delete(`/chart/chartData/${projectId}/${versionId}/${sizingId}`);
    } catch {}
  }

  async calculateActuator(request: any, isRotary: boolean): Promise<any> {
    const data = isRotary ? await this.calculateActuatorRotary(request) : await this.calculateActuatorLinear(request);
    return this.formatActuatorResult(data, isRotary);
  }

  async calculateActuatorRotary(request: any): Promise<any> {
    const response = await this.http.post('/calculate/controlactuatorrotary', request);
    return response.data;
  }

  async calculateActuatorLinear(request: any): Promise<any> {
    const response = await this.http.post('/calculate/controlactuatorlinear', request);
    return response.data;
  }

  async calculateOnOff(request: any, isRotary: boolean): Promise<any> {
    const data = isRotary ? await this.calculateOnOffRotary(request) : await this.calculateOnOffLinear(request);
    return this.formatActuatorResult(data);
  }

  async calculateOnOffRotary(request: any): Promise<ControlFlowResult> {
    const response = await this.http.post('/calculate/rotaryonoff', request);
    return response.data;
  }

  async calculateOnOffLinear(request: any): Promise<ControlFlowResult> {
    const response = await this.http.post('/calculate/linearonoff', request);
    return response.data;
  }

  async calculateSil(request: SilCalculationRequest): Promise<SilResult> {
    const response = await this.http.post('/sil/calculate', request);
    return get(response, ['data', 'result']);
  }

  async calculateVapourPressure(params: VapourPressureRequest): Promise<number> {
    const resp = await this.http.get('UISupport/vapourPressure', { params });
    return Number(get(resp, ['data', 'vapourPressure']).toPrecision(3));
  }

  async calculateCompressibility(params: CompressibilityRequest): Promise<number> {
    const resp = await this.http.get('UISupport/compressibility', { params });
    return Number(get(resp, ['data', 'compressibility']).toPrecision(3));
  }

  async convertUnit(params: UnitConversionRequest): Promise<number> {
    const response = await this.http.get('UISupport/unitConversion', {
      params
    });
    return get(response, ['data', 'convertedValue']);
  }

  async convertLiquidFlowrate(params: LiquidFlowrateUnitConversion): Promise<number> {
    const response = await this.http.get('UISupport/liquidFlowrateConversion', {
      params
    });
    return get(response, ['data', 'convertedValue']);
  }

  async convertGasFlowrate(params: GasFlowrateUnitConversion): Promise<number> {
    const response = await this.http.get('UISupport/gasFlowrateConversion', {
      params
    });
    return get(response, ['data', 'convertedValue']);
  }

  private getFlowCases(device: any) {
    if (!device) {
      return [];
    }

    return device.flowCases.map((fc: any) => ({
      caseId: fc.caseId,
      rto: fc.rto,
      rtc: fc.rtc
    }));
  }

  formatActuatorResult(data: any, hasFlowCases: boolean = false): any {
    if (!data) {
      return {
        errorMessage: 'No result',
        hasResult: false
      };
    }

    if (data.errorMessage) {
      return {
        sizingId: data.sizingId,
        errorMessage: data.errorMessage,
        hasResult: data.hasResult
      };
    }

    const result: any = {
      sizingId: data.sizingId,
      errorMessage: undefined,
      torqueUnit: data.torqueUnit,
      hasResult: data.hasResult
    };

    if (data.actuator) {
      result.actuator = hasFlowCases ? { flowCases: this.getFlowCases(data.actuator) } : this.getData(data.actuator);

      result.actuator.size = data.actuator.size;
      result.actuator.resultStatus = data.actuator.resultStatus;
      result.actuator.bto = data.actuator.bto;
      result.actuator.etc = data.actuator.etc;
      result.actuator.springRange = data.actuator.springRange;
    }

    if (data.load) {
      result.load = hasFlowCases ? { flowCases: this.getFlowCases(data.load) } : this.getData(data.load);

      result.load.bto = data.load.bto;
      result.load.etc = data.load.etc;
    }

    if (data.valve) {
      result.valve = hasFlowCases ? { flowCases: this.getFlowCases(data.valve) } : this.getData(data.valve);
      result.valve.bonnet = data.valve.bonnet;
      result.valve.recoSF = data.valve.recoSF;
      result.valve.bto = data.valve.bto;
      result.valve.etc = data.valve.etc;
    }

    if (data.safety && !hasFlowCases) {
      result.safety = this.getData(data.safety);
    }

    if (data.valveDetails) {
      result.valveDetails = data.valveDetails;
    }

    return result;
  }

  private getData(device: any): any {
    return pick(device, ['bto', 'rto', 'eto', 'btc', 'rtc', 'etc']);
  }
}

const calculationService = new CalculationService(Axios);

export { calculationService };
