import { DefaultUnits } from '@/types';

export const defaultUnits: DefaultUnits = {
  pipelineSizeUnit: 'mm',
  liquidDensityUnit: 'kg/m3',
  gasDensityUnit: 'kg/dm3',
  criticalPressureUnit: 'barG',
  dynamicViscosityUnit: 'cp',
  kinematicViscosityUnit: 'm2/s',
  liquidFlowRateUnit: 'kg/h',
  gasFlowRateUnit: 'kg/h',
  temperatureUnit: 'degC',
  flowcasePressureUnit: 'barG',
  vapourPressureUnit: 'barG',
  shutoffPressureUnit: 'bar',
  designPressureUnit: 'barG',
  torqueUnit: 'Nm',
  capacityUnit: 'FpCv',
  flowVelocityUnit: 'm/s'
};

export const defaultUSUnits: DefaultUnits = {
  pipelineSizeUnit: 'in',
  liquidDensityUnit: 'lb/ft3',
  gasDensityUnit: 'lb/ft3',
  criticalPressureUnit: 'psiG',
  dynamicViscosityUnit: 'cp',
  kinematicViscosityUnit: 'in2/s',
  liquidFlowRateUnit: 'gpm',
  gasFlowRateUnit: 'scfh',
  temperatureUnit: 'degF',
  flowcasePressureUnit: 'psiG',
  vapourPressureUnit: 'psiG',
  shutoffPressureUnit: 'psi',
  designPressureUnit: 'psiG',
  torqueUnit: 'ftlb',
  capacityUnit: 'FpCv',
  flowVelocityUnit: 'ft/s'
};
