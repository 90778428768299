import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import {
  Actuator,
  Architecture,
  Attenuator,
  DefaultUnits,
  DiagnosticCoverage,
  Entry,
  GasEntry,
  GasMassType,
  IterableObject,
  LiquidDensityType,
  LiquidEntry,
  PulpType,
  RootState,
  SafetyFactorSelectionOption,
  SafetyPosition,
  SerieEntry,
  SilDeviceType,
  UnitsState,
  Valve,
  ValveFunction,
  ValveMotion
} from '@/types';
import { cloneDeep, isEmpty, get } from 'lodash';
import { Convert, listService } from '@/services';
import { Time } from '@/types/units';
import { ViscosityType } from '@/types/ViscosityType';
import { defaultFluidTypes } from '@/types/units/FluidTypes';
import { defaultUnits, defaultUSUnits } from './defaults/unitDefaults';

export const state: UnitsState = {
  liquidDensityTypes: [
    { value: LiquidDensityType.Density, name: 'Liquid Density' },
    { value: LiquidDensityType.Gravity, name: 'Liquid Specific Gravity' }
  ],
  safetyFactorSelectionOptions: [
    {
      value: SafetyFactorSelectionOption.Automatic,
      name: 'Recommended safety factor'
    },
    { value: SafetyFactorSelectionOption.Custom, name: 'Custom safety factor' }
  ],
  viscosityTypes: [
    { value: ViscosityType.Kinematic, name: 'Kinematic viscosity' },
    { value: ViscosityType.Dynamic, name: 'Dynamic viscosity' }
  ],
  gasMassTypes: [
    { value: GasMassType.MolecularWeight, name: 'Molecular Weight' },
    { value: GasMassType.SpecificGravity, name: 'Gas Specific Gravity' },
    { value: GasMassType.InletDensity, name: 'Inlet Density Flow Cases' }
  ],
  densityUnits: [{ name: 'kg/m3' }, { name: 'kg/dm3' }, { name: 'lb/ft3' }],
  sizeUnits: [{ name: 'mm' }, { name: 'in' }],
  capacityUnits: [{ name: 'Cv' }, { name: 'Kv' }, { name: 'FpCv' }, { name: 'FpKv' }],
  velocityUnits: [{ name: 'm/s' }, { name: 'ft/s' }],
  scheduleUnits: [],
  liquidFlowRateUnits: [
    { name: 'm3/h' },
    { name: 'gpm' },
    { name: 'l/min' },
    { name: 'l/s' },
    { name: 'brl/day' },
    { name: 'kg/h' },
    { name: 'lb/h' },
    { name: 'ton/h' },
    { name: 'ton/day' },
    { name: 'kg/s' }
  ],
  gasFlowRateUnits: [
    { name: 'kg/h' },
    { name: 'lb/h' },
    { name: 'ton/h' },
    { name: 'ton/day' },
    { name: 'kg/s' },
    { name: 'scfh' },
    { name: 'scfm' },
    { name: 'mscfd' },
    { name: 'mmscfd' },
    { name: 'Nm3/h' },
    { name: 'Nl/s' }
  ],
  temperatureUnits: [
    { value: 'degC', name: 'Celsius' },
    { value: 'degF', name: 'Fahrenheit' },
    { value: 'K', name: 'Kelvin' }
  ],
  pressureUnits: [
    { name: 'barA' },
    { name: 'barG' },
    { name: 'psiA' },
    { name: 'psiG' },
    { name: 'kPaA' },
    { name: 'kPaG' },
    { name: 'MPaA' },
    { name: 'MPaG' },
    { name: 'kg/cm2A' },
    { name: 'kg/cm2G' },
    { name: 'mmHgA' },
    { name: 'mmHgG' },
    { name: 'atmA' },
    { name: 'mH2OA' },
    { name: 'mH2OG' },
    { name: 'ftH2OA' },
    { name: 'ftH2OG' }
  ],
  pressureDifferenceUnits: [
    { name: 'bar' },
    { name: 'psi' },
    { name: 'kPa' },
    { name: 'MPa' },
    { name: 'kg/cm2' },
    { name: 'mmHg' },
    { name: 'atm' },
    { name: 'mH2O' },
    { name: 'ftH2O' }
  ],
  torqueUnits: [...torqueUnits()],
  kinematicViscosityUnits: {
    fresh: false,
    data: [{ name: 'm2/s' }, { name: 'St' }, { name: 'in2/s' }, { name: 'cSt' }, { name: 'cm2/s' }]
  },
  dynamicViscosityUnits: {
    fresh: false,
    data: [{ name: 'cp' }, { name: 'poise' }, { name: 'Ns/m2' }, { name: 'lb/fts' }, { name: 'mPas' }]
  },
  timeUnits: [
    { name: 'Days', value: Time.Day },
    { name: 'Months', value: Time.Month }
  ],
  thicknessTypes: [{ name: 'Schedule' }, { name: 'Thickness' }],
  fluidTypes: cloneDeep(defaultFluidTypes),
  fluidPhaseTypes: [
    { name: 'Normal' },
    { name: 'Dirty' },
    { name: 'Sticky' },
    { name: 'Nonlubricant' },
    { name: 'ESD' },
    { name: 'PDS polymer' },
    { name: 'High cycle' }
  ],
  liquids: {
    fresh: false,
    data: [
      { name: 'Acetic Acid', density: 1049, criticalPressure: 57.9 },
      {
        name: 'Acetic Anhydride',
        density: 1082,
        criticalPressure: 46.6
      },
      { name: 'Acetone', density: 792, criticalPressure: 47.6 },
      { name: 'Aniline', density: 1022, criticalPressure: 53.1 },
      { name: 'Benzene', density: 879, criticalPressure: 48.6 },
      { name: 'Bromine', density: 3120, criticalPressure: 103 },
      { name: 'Butyl Alcohol', density: 804, criticalPressure: 48.9 },
      { name: 'Carbon Disulfide', density: 1263, criticalPressure: 74 },
      {
        name: 'Carbon Tetrachloride',
        density: 1594,
        criticalPressure: 45.6
      },
      { name: 'Chlorobenzene', density: 1107, criticalPressure: 45.2 },
      { name: 'Chloroform', density: 1489, criticalPressure: 54.7 },
      { name: 'Cyclohexane', density: 779, criticalPressure: 40.3 },
      { name: 'Ethyl Acetate', density: 900, criticalPressure: 38.3 },
      { name: 'Ethyl Alcohol', density: 790, criticalPressure: 63.9 },
      { name: 'Ethyl Ether', density: 714, criticalPressure: 36.5 },
      {
        name: 'Ethylene Trichloride',
        density: 1464,
        criticalPressure: 50.2
      },
      { name: 'Heptane', density: 684, criticalPressure: 27.2 },
      { name: 'Hexane', density: 659, criticalPressure: 30 },
      { name: 'Mercury', density: 13546, criticalPressure: 1056 },
      { name: 'Methyl Alcohol', density: 792, criticalPressure: 79.7 },
      { name: 'Methyl Sulfide', density: 845, criticalPressure: 55.3 },
      { name: 'Octane', density: 702, criticalPressure: 25 },
      { name: 'Pentane', density: 626, criticalPressure: 33.4 },
      { name: 'Propionic Acid', density: 993, criticalPressure: 53.7 },
      { name: 'Propylalcohol', density: 804, criticalPressure: 50.7 },
      { name: 'Propylamine', density: 719, criticalPressure: 47.4 },
      { name: 'Pyridine', density: 983, criticalPressure: 60.8 },
      { name: 'Toluene', density: 866, criticalPressure: 42.2 },
      { name: 'Water', density: 997, criticalPressure: 221.2 }
    ]
  },
  gases: {
    fresh: false,
    data: [
      {
        name: 'Acetone',
        molecularWeight: 58.08,
        specificHeats: 1.35
      },
      {
        name: 'Acetylene',
        molecularWeight: 26.03,
        specificHeats: 1.26
      },
      {
        name: 'Air',
        molecularWeight: 28.969,
        specificHeats: 1.4
      },
      {
        name: 'Ammonia',
        molecularWeight: 17.03,
        specificHeats: 1.29
      },
      {
        name: 'Argon',
        molecularWeight: 39.94,
        specificHeats: 1.67
      },
      {
        name: 'Benzene',
        molecularWeight: 78.08,
        specificHeats: 1.1
      },
      {
        name: 'Bromine',
        molecularWeight: 159.84,
        specificHeats: 1.32
      },
      {
        name: 'Butane',
        molecularWeight: 58.1,
        specificHeats: 1.35
      },
      {
        name: 'Carbon Dioxide',
        molecularWeight: 44.01,
        specificHeats: 1.3
      },
      {
        name: 'Carbon Monoxide',
        molecularWeight: 28.01,
        specificHeats: 1.4
      },
      {
        name: 'Carbon Tetrachloride',
        molecularWeight: 153.84,
        specificHeats: 1.35
      },
      {
        name: 'Chlorine',
        molecularWeight: 70.91,
        specificHeats: 1.35
      },
      {
        name: 'Cyanogen',
        molecularWeight: 52.04,
        specificHeats: 1.26
      },
      {
        name: 'Cyclohexane',
        molecularWeight: 84.13,
        specificHeats: 1.08
      },
      {
        name: 'Cyclopentane',
        molecularWeight: 70.1,
        specificHeats: 1.35
      },
      {
        name: 'Ethane',
        molecularWeight: 30.05,
        specificHeats: 1.19
      },
      {
        name: 'Ethyl Alcohol',
        molecularWeight: 46.07,
        specificHeats: 1.13
      },
      {
        name: 'Ethyl Chloride',
        molecularWeight: 64.5,
        specificHeats: 1.35
      },
      {
        name: 'Ethylene',
        molecularWeight: 28.04,
        specificHeats: 1.25
      },
      {
        name: 'Fluorine',
        molecularWeight: 38.0,
        specificHeats: 1.35
      },
      {
        name: 'Freon',
        molecularWeight: 120.9,
        specificHeats: 1.35
      },
      {
        name: 'Helium',
        molecularWeight: 4.003,
        specificHeats: 1.66
      },
      {
        name: 'Heptane',
        molecularWeight: 100.2,
        specificHeats: 1.35
      },
      {
        name: 'Hexane',
        molecularWeight: 86.14,
        specificHeats: 1.08
      },
      {
        name: 'Hydrogen',
        molecularWeight: 2.016,
        specificHeats: 1.41
      },
      {
        name: 'Hydrogen Chloride',
        molecularWeight: 36.47,
        specificHeats: 1.41
      },
      {
        name: 'Hydrogen Sulfide',
        molecularWeight: 34.07,
        specificHeats: 1.32
      },
      {
        name: 'Isopropyl Alcohol',
        molecularWeight: 60.09,
        specificHeats: 1.35
      },
      {
        name: 'Mercury',
        molecularWeight: 200.61,
        specificHeats: 1.67
      },
      {
        name: 'Methane',
        molecularWeight: 16.04,
        specificHeats: 1.31
      },
      {
        name: 'Methyl Alcohol',
        molecularWeight: 32.04,
        specificHeats: 1.2
      },
      {
        name: 'Methyl Bromide',
        molecularWeight: 94.95,
        specificHeats: 1.35
      },
      {
        name: 'Methyl Chloride',
        molecularWeight: 50.49,
        specificHeats: 1.35
      },
      {
        name: 'Napthalene',
        molecularWeight: 128.16,
        specificHeats: 1.35
      },
      {
        name: 'Natural Gas',
        molecularWeight: 17.39,
        specificHeats: 1.32
      },
      {
        name: 'Neon',
        molecularWeight: 20.18,
        specificHeats: 1.67
      },
      {
        name: 'Nitric Oxide',
        molecularWeight: 30.01,
        specificHeats: 1.4
      },
      {
        name: 'Nitrogen',
        molecularWeight: 28.02,
        specificHeats: 1.4
      },
      {
        name: 'Nitrous Oxide',
        molecularWeight: 44.1,
        specificHeats: 1.3
      },
      {
        name: 'Octane',
        molecularWeight: 114.2,
        specificHeats: 1.35
      },
      {
        name: 'Oxygen',
        molecularWeight: 32.0,
        specificHeats: 1.4
      },
      {
        name: 'Ozone',
        molecularWeight: 48.0,
        specificHeats: 1.29
      },
      {
        name: 'Pentane',
        molecularWeight: 72.12,
        specificHeats: 1.09
      },
      {
        name: 'Propane',
        molecularWeight: 44.1,
        specificHeats: 1.35
      },
      {
        name: 'Propylene',
        molecularWeight: 42.08,
        specificHeats: 1.14
      },
      {
        name: 'Steam',
        molecularWeight: 18.02,
        specificHeats: 1.3
      },
      {
        name: 'Styrene',
        molecularWeight: 104.1,
        specificHeats: 1.36
      },
      {
        name: 'Sulphur Dioxide',
        molecularWeight: 64.6,
        specificHeats: 1.25
      },
      {
        name: 'Toluene',
        molecularWeight: 92.1,
        specificHeats: 1.35
      }
    ]
  },
  valveTypes: [],
  valveSeries: [],
  valveSizes: [],
  pipeSizes: [],
  pressureClasses: [],
  seatCodes: [],
  sizeReductions: [],
  trimOptions: [],
  designStyles: [],
  flowDirections: [],
  trimCharacteristics: [],
  seatTypes: [],
  bearingMaterials: [],
  packingMaterials: [],
  seatAmounts: [],
  pulpTypes: [...pulpTypes()],
  attenuatorTypes: [],
  attenuatorSizes: [],
  resistorTypes: [{ name: 'Orifice' }, { name: 'Baffle' }],
  resistorSizes: [],
  actuatorSeries: {
    fresh: false,
    data: []
  },
  springActions: [],
  actuatorSizes: [],
  springRanges: [],
  trimTypes: [],
  seatLeakages: [],
  bonnetTypes: [...bonnetTypes()],
  insulationTypes: [],
  finalElementArchitectures: [
    { name: Architecture.Architecture1oo1 },
    { name: Architecture.Architecture1oo1D },
    { name: Architecture.Architecture1oo2 }
  ],
  instrumentArchitectures: [
    { name: Architecture.None },
    { name: Architecture.Architecture1oo1 },
    { name: Architecture.Architecture1oo2 },
    { name: Architecture.Architecture2oo2 }
  ],
  safetyPosition: [
    { name: 'Close', value: SafetyPosition.Close },
    { name: 'Open', value: SafetyPosition.Open }
  ],
  diagnosticCoverage: [
    { name: 'No diagnostics test', value: DiagnosticCoverage.None },
    {
      name: 'Valve + Actuator + ValvGuard PST',
      value: DiagnosticCoverage.ValveGuard
    },
    {
      name: 'Valve + Actuator + Manual PST',
      value: DiagnosticCoverage.Manual
    }
  ],
  silValveNames: {
    fresh: false,
    data: []
  },
  silActuatorNames: {
    fresh: false,
    data: []
  },
  silInstrumentNames: {
    fresh: false,
    data: []
  },
  silIntelligentPstNames: {
    fresh: false,
    data: []
  },
  // TODO: Maybe these don't have to be here. Use store/defaults/unitDefaults.ts directly
  defaultUnits: cloneDeep(defaultUnits),
  defaultUSUnits: cloneDeep(defaultUSUnits)
};

export const getters: GetterTree<UnitsState, RootState> = {
  searchParams(state, getters, rootState, rootGetters): any {
    return (...excluded: string[]) => {
      const obj = get(rootState, ['sizing', 'valve'], {}) as IterableObject<object>;
      const invalidProps = getInvalidValveProps(obj, state);
      invalidProps.forEach((invalidProp) => {
        excluded.push(invalidProp);
      });

      const params: any = {};
      const valve = get(rootState, ['sizing', 'valve'], {}) as Valve;

      if (valve) {
        if (valve.valveMotion && !excluded.includes('valveMotion')) {
          params.ValveMotion = valve.valveMotion;
        }

        const valveType = valve.seriesType || valve.type;

        if (valveType && !excluded.includes('type')) {
          params.Type = valveType;
        }

        if (valve.series && !excluded.includes('series')) {
          params.Series = valve.series;
        }

        const valveSize = rootGetters['sizing/valveSize'];

        if ((valveSize || valveSize === 0) && !excluded.includes('size')) {
          params.Size = valveSize;
        }

        if (valve.pressureClass && !excluded.includes('pressureClass')) {
          params.PressureClass = valve.pressureClass;
        }

        if (valve.designStyle && !excluded.includes('designStyle')) {
          params.DesignStyle = valve.designStyle;
        }

        if (valve.flowDirection && !excluded.includes('flowDirection')) {
          params.FlowDirection = valve.flowDirection;
        }

        if (valve.trimOption && !excluded.includes('trimOption')) {
          params.TrimOption = valve.trimOption;
        }

        if (valve.trimCharacteristics && !excluded.includes('trimCharacteristics')) {
          params.TrimCharacteristic = valve.trimCharacteristics;
        }

        if (valve.seatCode && !excluded.includes('seatCode')) {
          params.SeatCode = valve.seatCode;
        }

        if ((valve.sizeReduction || valve.sizeReduction === 0) && !excluded.includes('sizeReduction')) {
          params.SizeReduction = valve.sizeReduction;
        }
      }

      return params;
    };
  },
  searchOnlyParams: function (state, _, rootState): any {
    return (...included: string[]) => {
      const obj = get(rootState, ['sizing', 'valve'], {}) as IterableObject<object>;
      const invalidProps = getInvalidValveProps(obj, state);
      invalidProps.forEach((invalidProp) => {
        for (let i = 0; i < included.length; i++) {
          if (included[i] === invalidProp) {
            included.splice(i, 1);
            break;
          }
        }
      });

      const params: any = {};
      const valve = get(rootState, ['sizing', 'valve'], {}) as Valve;

      if (valve) {
        if (included.includes('type') && !included.includes('valveType') && valve.type) {
          params.Type = valve.type;
        }

        if (included.includes('valveType') && (valve.seriesType || valve.type)) {
          params.Type = valve.seriesType || valve.type;
        }

        if (included.includes('series') && valve.series) {
          params.Series = valve.series;
        }

        if (included.includes('pressureClass') && valve.pressureClass) {
          params.PressureClass = valve.pressureClass;
        }
      }

      return params;
    };
  },
  rotaryParams(state, getters, rootState, rootGetters): any {
    return (...excluded: string[]) => {
      const obj = get(rootState, ['sizing', 'valve'], {}) as IterableObject<object>;
      const invalidProps = getInvalidValveProps(obj, state);
      invalidProps.forEach((invalidProp) => {
        excluded.push(invalidProp);
      });
      const params: any = {};
      const valve = get(rootState, ['sizing', 'valve'], {}) as Valve;

      if (valve) {
        if (valve.series && !excluded.includes('series')) {
          params.Series = valve.series;
        }

        const valveSize = rootGetters['sizing/valveSize'];

        if ((valveSize || valveSize === 0) && !excluded.includes('size')) {
          params.Size = valveSize;
        }

        if (valve.designStyle && !excluded.includes('designStyle')) {
          params.DesignStyle = valve.designStyle;
        }

        if (valve.pressureClass && !excluded.includes('pressureClass')) {
          params.PressureClass = valve.pressureClass;
        }

        if (valve.packingMaterial && !excluded.includes('packingMaterial')) {
          params.PackingMaterial = valve.packingMaterial;
        }

        if (valve.bearingMaterial && !excluded.includes('bearingMaterial')) {
          params.BearingMaterial = valve.bearingMaterial;
        }

        if (valve.flowDirection && !excluded.includes('flowDirection')) {
          params.FlowDirection = valve.flowDirection;
        }

        if (valve.seatType && !excluded.includes('seatType')) {
          params.SeatType = valve.seatType;
        }

        if (valve.seatCode && !excluded.includes('seatCode')) {
          params.SeatCode = valve.seatCode;
        }

        if ((valve.seatAmount || valve.seatAmount === 0) && !excluded.includes('seatAmount')) {
          params.SeatAmount = valve.seatAmount;
        }
      }

      return params;
    };
  },
  linearSerieParam(state, getters, rootState) {
    const valve: any = get(rootState, ['sizing', 'valve'], {});
    return {
      Series: valve.series,
      PressureClass: valve.pressureClass
    };
  },
  linearParams(state, getters, rootState, rootGetters): any {
    return (...excluded: string[]) => {
      const obj = get(rootState, ['sizing', 'valve'], {}) as IterableObject<object>;
      const invalidProps = getInvalidValveProps(obj, state);
      invalidProps.forEach((invalidProp) => {
        excluded.push(invalidProp);
      });
      const params: any = {};
      const valve = get(rootState, ['sizing', 'valve'], {}) as Valve;

      if (valve) {
        if (valve.series) {
          params.Series = valve.series;
        }

        if (valve.pressureClass && !excluded.includes('pressureClass')) {
          params.PressureClass = valve.pressureClass;
        }

        const valveSize = rootGetters['sizing/valveSize'];

        if ((valveSize || valveSize === 0) && !excluded.includes('size')) {
          params.Size = valveSize;
        }

        if (valve.packingMaterial && !excluded.includes('packingMaterial')) {
          params.PackingMaterial = valve.packingMaterial;
        }

        if (valve.seatType && !excluded.includes('seatType')) {
          params.SeatType = valve.seatType;
        }

        if (valve.flowDirection && !excluded.includes('flowDirection')) {
          params.FlowDirection = valve.flowDirection;
        }

        if (valve.trimType && !excluded.includes('trimType')) {
          params.TrimType = valve.trimType;
        }

        if (valve.seatLeakage && !excluded.includes('seatLeakage')) {
          params.SeatLeakage = valve.seatLeakage;
        }

        if (valve.bonnetType && !excluded.includes('bonnetType')) {
          params.BonnetType = valve.bonnetType;
        }

        const valveSizeReduction = rootGetters['sizing/valveSizeReduction'];

        if ((valveSizeReduction || valveSizeReduction === 0) && !excluded.includes('reduction')) {
          params.TrimReduction = Convert.toTrimReduction(valveSizeReduction);
        }
      }

      return params;
    };
  },
  attenuatorParams: function (state, getters, rootState, rootGetters): any {
    return (...excluded: string[]) => {
      const params: any = {};

      const attenuator = get(rootState, ['sizing', 'attenuator']);

      if (attenuator) {
        if (attenuator.type && !excluded.includes('type')) {
          params.Type = attenuator.type;
        }

        if (attenuator.size && !excluded.includes('size')) {
          params.Size = attenuator.size;
        }
      }

      if (!params.Size) {
        if (!excluded.includes('minSize')) {
          const valveSize = rootGetters['sizing/valveSize'];

          if (valveSize) {
            params.MinimumSize = valveSize;
          }
        }

        if (!excluded.includes('maxSize')) {
          const pipeline = get(rootState, ['sizing', 'pipelineSizes']);

          if (pipeline && pipeline.outletSize) {
            params.MaximumSize =
              pipeline.unit === 'in' ? Convert.inchesToMillimeters(pipeline.outletSize) : pipeline.outletSize;
          }
        }
      }

      return params;
    };
  },
  sizeRange(state, getters, rootState, rootGetters): any {
    const params: any = {};
    const minSize = rootGetters['sizing/valveSize'];

    let maxSize;
    const pipeline = get(rootState, ['sizing', 'pipelineSizes']);

    if (pipeline && pipeline.outletSize) {
      maxSize = pipeline.unit === 'in' ? Convert.inchesToMillimeters(pipeline.outletSize) : pipeline.outletSize;
    }

    if (minSize !== undefined) {
      params.MinimumSize = minSize;
    }

    if (maxSize !== undefined) {
      params.MaximumSize = maxSize;
    }

    return params;
  },
  actuatorParams: function (state, getters, rootState): any {
    return (...excluded: string[]) => {
      const obj = get(rootState, ['sizing', 'actuator'], {}) as IterableObject<object>;
      const invalidProps = getInvalidActuatorProps(obj, state);
      invalidProps.forEach((invalidProp) => {
        excluded.push(invalidProp);
      });
      const params: any = {};
      const actuator: Actuator = get(rootState, ['sizing', 'actuator']);

      if (actuator) {
        if (actuator.series && !excluded.includes('series')) {
          params.Series = actuator.series;
        }

        if (actuator.size && !excluded.includes('size')) {
          params.Size = actuator.size;
        }

        if (actuator.springAction && !excluded.includes('springAction')) {
          params.SpringAction = actuator.springAction;
        }

        if (actuator.springRange && !excluded.includes('springRange')) {
          params.SpringRange = actuator.springRange;
        }
      }

      return params;
    };
  },
  actuatorSerieParam(state, getters, rootState): any {
    return { Series: get(rootState, ['sizing', 'actuator', 'series']) };
  },
  liquidByName({ liquids }): any {
    return (liquidName: string): LiquidEntry | undefined =>
      liquidName ? cloneDeep(liquids.data.find((liquid) => liquid.name === liquidName)) : undefined;
  },
  gasByName({ gases }): any {
    return (gasName: string): GasEntry | undefined =>
      gasName ? cloneDeep(gases.data.find((gas) => gas.name === gasName)) : undefined;
  },
  valveEntryBySeries({ valveSeries }): any {
    return (serieName: string): SerieEntry | undefined | string =>
      serieName ? cloneDeep(valveSeries.find((series) => series.name === serieName)) : undefined;
  },
  liquidDensityTypes(state): Entry[] {
    return cloneDeep(state.liquidDensityTypes);
  },
  safetyFactorSelectionOptions(state): Entry[] {
    return cloneDeep(state.safetyFactorSelectionOptions);
  },
  viscosityTypes(state): Entry[] {
    return cloneDeep(state.viscosityTypes);
  },
  gasMassTypes(state): Entry[] {
    return cloneDeep(state.gasMassTypes);
  },
  densityUnits(state): Entry[] {
    return cloneDeep(state.densityUnits);
  },
  sizeUnits(state): Entry[] {
    return cloneDeep(state.sizeUnits);
  },
  capacityUnits(state): Entry[] {
    return cloneDeep(state.capacityUnits);
  },
  kinematicViscosityUnits(state): Entry[] {
    return cloneDeep(state.kinematicViscosityUnits.data);
  },
  dynamicViscosityUnits(state): Entry[] {
    return cloneDeep(state.dynamicViscosityUnits.data);
  },
  scheduleUnits(state): Entry[] {
    return cloneDeep(state.scheduleUnits);
  },
  velocityUnits(state): Entry[] {
    return cloneDeep(state.velocityUnits);
  },
  liquidFlowRateUnits(state): Entry[] {
    return cloneDeep(state.liquidFlowRateUnits);
  },
  gasFlowRateUnits(state): Entry[] {
    return cloneDeep(state.gasFlowRateUnits);
  },
  temperatureUnits(state): Entry[] {
    return cloneDeep(state.temperatureUnits);
  },
  pressureUnits(state): Entry[] {
    return cloneDeep(state.pressureUnits);
  },
  pressureDifferenceUnits(state): Entry[] {
    return cloneDeep(state.pressureDifferenceUnits);
  },
  torqueUnits(state, _, rootState): Entry[] {
    switch (get(rootState, ['sizing', 'valve', 'valveMotion'])) {
      case ValveMotion.Rotary:
        return state.torqueUnits.filter((e) => e.type === ValveMotion.Rotary);
      case ValveMotion.Linear:
        return state.torqueUnits.filter((e) => e.type === ValveMotion.Linear);
      default:
        return cloneDeep(state.torqueUnits);
    }
  },
  rotaryTorqueUnits(state): Entry[] {
    return cloneDeep(state.torqueUnits.filter((e) => e.type === ValveMotion.Rotary));
  },
  timeUnits(state): Entry[] {
    return cloneDeep(state.timeUnits);
  },
  thicknessTypes(state): Entry[] {
    return cloneDeep(state.thicknessTypes);
  },
  fluidTypes(state): Entry[] {
    return cloneDeep(state.fluidTypes);
  },
  fluidPhaseTypes(state): Entry[] {
    return cloneDeep(state.fluidPhaseTypes);
  },
  liquids(state): SerieEntry[] {
    return cloneDeep(state.liquids.data);
  },
  gases(state): SerieEntry[] {
    return cloneDeep(state.gases.data);
  },
  valveTypes(state): Entry[] {
    return cloneDeep(state.valveTypes);
  },
  valveSeries(state): SerieEntry[] {
    return cloneDeep(state.valveSeries);
  },
  valveSizes(state): Entry[] {
    return cloneDeep(state.valveSizes);
  },
  pipeSizes(state): Entry[] {
    return cloneDeep(state.pipeSizes);
  },
  pressureClasses(state): Entry[] {
    return cloneDeep(state.pressureClasses);
  },
  seatCodes(state): Entry[] {
    return cloneDeep(state.seatCodes);
  },
  sizeReductions(state): Entry[] {
    return cloneDeep(state.sizeReductions);
  },
  trimOptions(state): Entry[] {
    return cloneDeep(state.trimOptions);
  },
  designStyles(state): Entry[] {
    return cloneDeep(state.designStyles);
  },
  flowDirections(state): Entry[] {
    return cloneDeep(state.flowDirections);
  },
  trimCharacteristics(state): Entry[] {
    return cloneDeep(state.trimCharacteristics);
  },
  seatTypes(state): Entry[] {
    return cloneDeep(state.seatTypes);
  },
  bearingMaterials(state): Entry[] {
    return cloneDeep(state.bearingMaterials);
  },
  packingMaterials(state): Entry[] {
    return cloneDeep(state.packingMaterials);
  },
  seatAmounts(state): Entry[] {
    return cloneDeep(state.seatAmounts);
  },
  pulpTypes(state): Entry[] {
    return cloneDeep(state.pulpTypes);
  },
  attenuatorTypes(state): Entry[] {
    return cloneDeep(state.attenuatorTypes);
  },
  attenuatorSizes(state): Entry[] {
    return cloneDeep(state.attenuatorSizes);
  },
  attenuator(state, getters, rootState): Attenuator {
    return cloneDeep(get(rootState, ['sizing', 'attenuator'], {})) as any;
  },
  resistorTypes(state): Entry[] {
    return cloneDeep(state.resistorTypes);
  },
  resistorSizes(state): Entry[] {
    return cloneDeep(state.resistorSizes);
  },
  actuatorSeries(state, _, rootState): SerieEntry[] {
    const actuatorSeries = get(state, ['actuatorSeries', 'data'], []);
    const valveMotion = get(rootState, ['sizing', 'valve', 'valveMotion'], '').toUpperCase();
    return valveMotion ? actuatorSeries.filter((entry) => entry.type === valveMotion) : cloneDeep(actuatorSeries);
  },
  springActions(state): Entry[] {
    return cloneDeep(state.springActions);
  },
  actuatorSizes(state): Entry[] {
    return cloneDeep(state.actuatorSizes);
  },
  springRanges(state): Entry[] {
    return cloneDeep(state.springRanges);
  },
  trimTypes(state): Entry[] {
    return cloneDeep(state.trimTypes);
  },
  seatLeakages(state): Entry[] {
    return cloneDeep(state.seatLeakages);
  },
  bonnetTypes(state): Entry[] {
    return cloneDeep(state.bonnetTypes);
  },

  finalElementArchitectures(state): Entry[] {
    return cloneDeep(state.finalElementArchitectures);
  },
  instrumentArchitectures(state): Entry[] {
    return cloneDeep(state.instrumentArchitectures);
  },
  safetyPosition(state): Entry[] {
    return cloneDeep(state.safetyPosition);
  },
  diagnosticCoverage(state): Entry[] {
    return cloneDeep(state.diagnosticCoverage);
  },
  silValveNames(state): Entry[] {
    return cloneDeep(state.silValveNames.data);
  },
  silActuatorNames(state): Entry[] {
    return cloneDeep(state.silActuatorNames.data);
  },
  silInstrumentNames(state): Entry[] {
    return cloneDeep(state.silInstrumentNames.data);
  },
  silIntelligentPstNames(state): Entry[] {
    return cloneDeep(state.silIntelligentPstNames.data);
  },
  convertPipeSizeValueToDifferentUnit: () => (targetUnit: 'mm' | 'in', sourceValue: number) => {
    return convertUnit(targetUnit, sourceValue);
  },
  insulationTypes(state): Entry[] {
    return cloneDeep(state.insulationTypes);
  },
  defaultUnits(state): DefaultUnits {
    return cloneDeep(state.defaultUnits);
  },
  defaultUSUnits(state): DefaultUnits {
    return cloneDeep(state.defaultUSUnits);
  }
};

export const mutations: MutationTree<UnitsState> = {
  SET_KINEMATIC_VISCOSITY_UNITS(state, value: Entry[]) {
    if (value && value.length) {
      state.kinematicViscosityUnits.data.splice(0);
      state.kinematicViscosityUnits.data.push(...value);
      state.kinematicViscosityUnits.fresh = true;
    }
  },
  SET_DYNAMIC_VISCOSITY_UNITS(state, value: Entry[]) {
    if (value && value.length) {
      state.dynamicViscosityUnits.data.splice(0);
      state.dynamicViscosityUnits.data.push(...value);
      state.dynamicViscosityUnits.fresh = true;
    }
  },
  SET_LIQUIDS(state, value: LiquidEntry[]) {
    if (value && value.length) {
      state.liquids.data.splice(0);
      state.liquids.data.push(...value);
      state.liquids.fresh = true;
    }
  },
  SET_GASES(state, value: GasEntry[]) {
    if (value && value.length) {
      state.gases.data.splice(0);
      state.gases.data.push(...value);
      state.gases.fresh = true;
    }
  },
  SET_VALVE_TYPES(state, value: Entry[]) {
    state.valveTypes = value;
  },
  SET_VALVE_SERIES(state, value: SerieEntry[]) {
    state.valveSeries = value;
  },
  SET_VALVE_SIZES(state, value: Entry[]) {
    state.valveSizes = value;
  },
  SET_PIPE_SIZES(state, value: Entry[]) {
    state.pipeSizes = value;
  },
  SET_SCHEDULES(state, value: Entry[]) {
    state.scheduleUnits = value;
  },
  SET_PRESSURE_CLASSES(state, value: Entry[]) {
    state.pressureClasses = value;
  },
  SET_SEAT_CODES(state, value: Entry[]) {
    state.seatCodes = value;
  },
  SET_SIZE_REDUCTIONS(state, value: Entry[]) {
    state.sizeReductions = value;
  },
  SET_TRIM_OPTIONS(state, value: Entry[]) {
    state.trimOptions = value;
  },
  SET_DESIGN_STYLES(state, value: Entry[]) {
    state.designStyles = value;
  },
  SET_FLOW_DIRECTIONS(state, value: Entry[]) {
    state.flowDirections = value;
  },
  SET_TRIM_CHARACTERISTICS(state, value: Entry[]) {
    state.trimCharacteristics = value;
  },
  SET_SEAT_TYPES(state, value: Entry[]) {
    state.seatTypes = value;
  },
  SET_BEARING_MATERIALS(state, value: Entry[]) {
    state.bearingMaterials = value;
  },
  SET_PACKING_MATERIALS(state, value: Entry[]) {
    state.packingMaterials = value;
  },
  SET_SEAT_AMOUNTS(state, value: Entry[]) {
    state.seatAmounts = value;
  },
  SET_ATTENUATOR_TYPES(state, value: Entry[]) {
    state.attenuatorTypes = value;
  },
  SET_ATTENUATOR_SIZES(state, value: Entry[]) {
    state.attenuatorSizes = value;
  },
  SET_RESISTOR_SIZES(state, value: Entry[]) {
    state.resistorSizes = value;
  },
  SET_ACTUATOR_SERIES(state, value: SerieEntry[]) {
    if (value && value.length) {
      state.actuatorSeries.data.splice(0);
      state.actuatorSeries.data.push(...value);
      state.actuatorSeries.fresh = true;
    }
  },
  SET_SPRING_ACTIONS(state, value: Entry[]) {
    state.springActions = value;
  },
  SET_ACTUATOR_SIZES(state, value: Entry[]) {
    state.actuatorSizes = value;
  },
  SET_SPRING_RANGES(state, value: Entry[]) {
    state.springRanges = value;
  },
  SET_TRIM_TYPES(state, value: Entry[]) {
    state.trimTypes = value;
  },
  SET_SEAT_LEAKAGES(state, value: Entry[]) {
    state.seatLeakages = value;
  },
  SET_BONNETTYPES(state, value: Entry[]) {
    state.bonnetTypes = value;
  },
  SET_SIL_VALVE_NAMES(state, value: Entry[]) {
    if (value && value.length) {
      state.silValveNames.data = [...value];
      state.silValveNames.fresh = true;
    }
  },
  SET_SIL_ACTUATOR_NAMES(state, value: Entry[]) {
    if (value && value.length) {
      state.silActuatorNames.data = [...value];
      state.silActuatorNames.fresh = true;
    }
  },
  SET_SIL_INSTRUMENT_NAMES(state, value: Entry[]) {
    if (value && value.length) {
      state.silInstrumentNames.data = [...value];
      state.silInstrumentNames.fresh = true;
    }
  },
  SET_SIL_INTELLIGENTPST_NAMES(state, value: Entry[]) {
    if (value && value.length) {
      state.silIntelligentPstNames.data = [...value];
      state.silIntelligentPstNames.fresh = true;
    }
  },
  SET_INSULATION_TYPES(state, value: Entry[]) {
    state.insulationTypes = value;
  },
  SET_FLUID_TYPES(state, value: Entry[]) {
    state.fluidTypes = value;
  },
  RESET_VALVE_SERIES(state) {
    state.valveSeries.splice(0);
  },
  RESET_VALVE_SIZES(state) {
    state.valveSizes.splice(0);
  },
  RESET_PRESSURE_CLASSES(state) {
    state.pressureClasses.splice(0);
  },
  RESET_SEAT_CODES(state) {
    state.seatCodes.splice(0);
  },
  RESET_SIZE_REDUCTIONS(state) {
    state.sizeReductions.splice(0);
  },
  RESET_TRIM_OPTIONS(state) {
    state.trimOptions.splice(0);
  },
  RESET_DESIGN_STYLES(state) {
    state.designStyles.splice(0);
  },
  RESET_FLOW_DIRECTIONS(state) {
    state.flowDirections.splice(0);
  },
  RESET_TRIM_CHARACTERISTICS(state) {
    state.trimCharacteristics.splice(0);
  },
  RESET_VALVE_TYPES(state) {
    state.valveTypes.splice(0);
  },
  RESET_SEAT_TYPES(state) {
    state.seatTypes.splice(0);
  },
  RESET_BEARING_MATERIALS(state) {
    state.bearingMaterials.splice(0);
  },
  RESET_PACKING_MATERIALS(state) {
    state.packingMaterials.splice(0);
  },
  RESET_SEAT_AMOUNTS(state) {
    state.seatAmounts.splice(0);
  },
  RESET_ATTENUATOR_TYPES(state) {
    state.attenuatorTypes.splice(0);
  },
  RESET_ATTENUATOR_SIZES(state) {
    state.attenuatorSizes.splice(0);
  },
  RESET_RESISTOR_SIZES(state) {
    state.resistorSizes.splice(0);
  },
  RESET_SPRING_ACTIONS(state) {
    state.springActions.splice(0);
  },
  RESET_ACTUATOR_SIZES(state) {
    state.actuatorSizes.splice(0);
  },
  RESET_SPRING_RANGES(state) {
    state.springRanges.splice(0);
  },
  RESET_TRIM_TYPES(state) {
    state.trimTypes.splice(0);
  },
  RESET_SEAT_LEAKAGES(state) {
    state.seatLeakages.splice(0);
  },
  RESET_BONNETTYPES(state) {
    state.bonnetTypes.splice(0);
    state.bonnetTypes.push(...bonnetTypes());
  }
};

export const actions: ActionTree<UnitsState, RootState> = {
  async refreshKinematicViscosityUnits({ commit, state }): Promise<void> {
    if (!state.kinematicViscosityUnits.fresh) {
      const units = await listService.unitFactors('viscosity', 'KINEMATIC');
      commit(mutations.SET_KINEMATIC_VISCOSITY_UNITS.name, units);
    }
  },
  async refreshDynamicViscosityUnits({ commit, state }): Promise<void> {
    if (!state.dynamicViscosityUnits.fresh) {
      const units = await listService.unitFactors('viscosity', 'DYNAMIC');
      commit(mutations.SET_DYNAMIC_VISCOSITY_UNITS.name, units);
    }
  },
  async refreshFluids({ commit, state }): Promise<void> {
    if (!state.liquids.fresh) {
      const liquids = await listService.liquids();
      commit(mutations.SET_LIQUIDS.name, liquids);
    }

    if (!state.gases.fresh) {
      const gases = await listService.gases();
      commit(mutations.SET_GASES.name, gases);
    }
  },
  async valveTypes({ commit, getters }): Promise<void> {
    const params: any = getters.searchOnlyParams('series');
    const value = await listService.valveTypes(params);
    commit(mutations.SET_VALVE_TYPES.name, value);
  },
  async pressureClasses({ commit, getters }): Promise<void> {
    const params: any = getters.searchOnlyParams('valveType', 'series');
    const value = await listService.pressureClasses(params);
    commit(mutations.SET_PRESSURE_CLASSES.name, value);
  },
  async pressureClassesByValveSeries({ commit, getters }): Promise<void> {
    const params: any = getters.searchOnlyParams('series');
    const value = await listService.pressureClasses(params);
    commit(mutations.SET_PRESSURE_CLASSES.name, value);
  },
  async valveSeries({ commit, getters, rootGetters }): Promise<void> {
    const isControl = rootGetters['sizing/valveFunction'] !== ValveFunction.OnOff;
    const params: any = getters.searchOnlyParams('type', 'pressureClass');
    const value = isControl ? await listService.valveSeriesControl(params) : await listService.valveSeriesOnOff(params);

    commit(mutations.SET_VALVE_SERIES.name, value);
  },
  async valveSizes({ commit, getters }, useAllQueryParams: boolean = true): Promise<void> {
    const params: any = useAllQueryParams
      ? getters.searchParams('type', 'size', 'valveMotion')
      : getters.searchOnlyParams('pressureClass', 'series');
    const value = await listService.valveSizes(params);
    commit(mutations.SET_VALVE_SIZES.name, value);
  },
  async pipeSizes({ commit, rootGetters }): Promise<void> {
    const pipelineSizes = rootGetters['sizing/pipelineSizes'];
    const params: any = {
      schedule: pipelineSizes.schedule ?? '',
      unit: pipelineSizes.unit
    };
    const value = await listService.pipeSizes(params);
    commit(mutations.SET_PIPE_SIZES.name, value);
  },
  async schedules({ commit, rootGetters }): Promise<void> {
    const pipelineSizes = rootGetters['sizing/pipelineSizes'];
    const params: any = {
      size: pipelineSizes.inletSize ?? '',
      unit: pipelineSizes.unit
    };
    const value = await listService.schedules(params);
    commit(mutations.SET_SCHEDULES.name, value);
  },
  async sizeReductions({ commit, getters }, useAllQueryParams: boolean = true): Promise<void> {
    const params: any = useAllQueryParams
      ? getters.searchParams('type', 'sizeReduction', 'valveMotion', 'trimOption')
      : getters.searchOnlyParams('pressureClass', 'series');
    const value = await listService.sizeReductions(params);
    commit(mutations.SET_SIZE_REDUCTIONS.name, value);
  },
  async designStyles({ commit, getters }, useAllQueryParams: boolean = true): Promise<void> {
    const params: any = useAllQueryParams
      ? getters.searchParams('type', 'designStyle', 'valveMotion')
      : getters.searchOnlyParams('pressureClass', 'series');
    const value = await listService.designStyles(params);
    commit(mutations.SET_DESIGN_STYLES.name, value);
  },
  async flowDirections({ commit, getters }, useAllQueryParams: boolean = true): Promise<void> {
    const params: any = useAllQueryParams
      ? getters.searchParams('type', 'flowDirection', 'valveMotion')
      : getters.searchOnlyParams('pressureClass', 'series');
    const value = await listService.flowDirections(params);
    commit(mutations.SET_FLOW_DIRECTIONS.name, value);
  },
  async seatCodes({ commit, getters }, useAllQueryParams: boolean = true): Promise<void> {
    const params: any = useAllQueryParams
      ? getters.searchParams('type', 'seatCode', 'valveMotion')
      : getters.searchOnlyParams('pressureClass', 'series');
    const value = await listService.seatCodes(params);
    commit(mutations.SET_SEAT_CODES.name, value);
  },
  async trimCharacteristics({ commit, getters }, useAllQueryParams: boolean = true): Promise<void> {
    const params: any = useAllQueryParams
      ? getters.searchParams('type', 'trimCharacteristics', 'valveMotion')
      : getters.searchOnlyParams('pressureClass', 'series');
    const value = await listService.trimCharacteristics(params);
    commit(mutations.SET_TRIM_CHARACTERISTICS.name, value);
  },
  async trimOptions({ commit, getters }, useAllQueryParams: boolean = true): Promise<void> {
    const params: any = useAllQueryParams
      ? getters.searchParams('type', 'trimOption', 'valveMotion')
      : getters.searchOnlyParams('pressureClass', 'series');
    const value = await listService.trimOptions(params);
    commit(mutations.SET_TRIM_OPTIONS.name, value);
  },
  async seatTypes({ commit, getters }, useAllQueryParams: boolean = true): Promise<void> {
    const params: any = useAllQueryParams
      ? getters.rotaryParams('seatType', 'bearingMaterial', 'packingMaterial')
      : getters.searchOnlyParams('pressureClass', 'series');
    const value = await listService.seatTypes(params);
    commit(mutations.SET_SEAT_TYPES.name, value);
  },
  async bearingMaterials({ commit, getters }, useAllQueryParams: boolean = true): Promise<void> {
    const params: any = useAllQueryParams
      ? getters.rotaryParams('bearingMaterial', 'packingMaterial')
      : getters.searchOnlyParams('pressureClass', 'series');
    const value = await listService.bearingMaterials(params);
    commit(mutations.SET_BEARING_MATERIALS.name, value);
  },
  async packingMaterials({ commit, getters }, useAllQueryParams: boolean = true): Promise<void> {
    const params: any = useAllQueryParams
      ? getters.rotaryParams('packingMaterial', 'bearingMaterial', 'trimPressureClass')
      : getters.searchOnlyParams('pressureClass', 'series');
    const value = await listService.packingMaterials(params);
    commit(mutations.SET_PACKING_MATERIALS.name, value);
  },
  async seatAmounts({ commit, getters }, useAllQueryParams: boolean = true): Promise<void> {
    const params: any = useAllQueryParams
      ? getters.rotaryParams('seatAmount', 'bearingMaterial', 'packingMaterial')
      : getters.searchOnlyParams('pressureClass', 'series');
    const value = await listService.seatAmounts(params);
    commit(mutations.SET_SEAT_AMOUNTS.name, value);
  },
  async trimTypes({ commit, getters }, useAllQueryParams: boolean = true): Promise<void> {
    const params: any = useAllQueryParams
      ? getters.linearParams('trimType')
      : getters.searchOnlyParams('pressureClass', 'series');
    const value = await listService.trimTypes(params);
    commit(mutations.SET_TRIM_TYPES.name, value);
  },
  async seatLeakages({ commit, getters }, useAllQueryParams: boolean = true): Promise<void> {
    const params: any = useAllQueryParams
      ? getters.linearParams('seatLeakage')
      : getters.searchOnlyParams('pressureClass', 'series');
    const value = await listService.seatLeakages(params);
    commit(mutations.SET_SEAT_LEAKAGES.name, value);
  },
  async bonnetTypes({ commit, getters }, useAllQueryParams: boolean = true): Promise<void> {
    const params: any = useAllQueryParams
      ? getters.linearParams('bonnetType')
      : getters.searchOnlyParams('pressureClass', 'series');
    const value = await listService.bonnetTypes(params);
    commit(mutations.SET_BONNETTYPES.name, value);
  },
  async attenuatorTypes({ commit, getters }): Promise<void> {
    const params: any = getters.attenuatorParams('type');
    const value = await listService.attenuatorTypes(params);
    commit(mutations.SET_ATTENUATOR_TYPES.name, value);
  },
  async attenuatorSizes({ commit, getters }): Promise<void> {
    const params: any = getters.attenuatorParams('size');
    const value = await listService.attenuatorSizes(params);
    commit(mutations.SET_ATTENUATOR_SIZES.name, value);
  },
  async resistorSizes({ commit, getters }): Promise<void> {
    const value = await listService.attenuatorSizes(getters.sizeRange);
    commit(mutations.SET_RESISTOR_SIZES.name, value);
  },
  async actuatorSeries({ commit, state }): Promise<void> {
    if (!state.actuatorSeries.fresh) {
      const value = await listService.actuatorSeries();
      commit(mutations.SET_ACTUATOR_SERIES.name, value);
    } else {
      const actuatorSeries = get(state, ['actuatorSeries', 'data'], []);
      const series = cloneDeep(actuatorSeries);
      // refresh dropdown values
      commit(mutations.SET_ACTUATOR_SERIES.name, series);
    }
  },
  async springActions({ commit, getters }, initial = false): Promise<void> {
    const params = initial ? getters.actuatorSerieParam : getters.actuatorParams('springAction');
    const value = await listService.springActions(params);
    commit(mutations.SET_SPRING_ACTIONS.name, value);
  },
  async actuatorSizes({ commit, getters }, initial = false): Promise<void> {
    const params = initial ? getters.actuatorSerieParam : getters.actuatorParams('size');
    const value = await listService.actuatorSizes(params);
    commit(mutations.SET_ACTUATOR_SIZES.name, value);
  },
  async springRanges({ commit, getters }, initial = false): Promise<void> {
    const params = initial ? getters.actuatorSerieParam : getters.actuatorParams('springRange');
    const value = await listService.springRanges(params);
    commit(mutations.SET_SPRING_RANGES.name, value);
  },
  async silValveNames({ state, commit }) {
    if (!state.silValveNames.fresh) {
      const names = await listService.silDeviceNames(SilDeviceType.Valve);
      commit(mutations.SET_SIL_VALVE_NAMES.name, names);
    }
  },
  async silActuatorNames({ state, commit }) {
    if (!state.silValveNames.fresh) {
      const names = await listService.silDeviceNames(SilDeviceType.Actuator);
      commit(mutations.SET_SIL_ACTUATOR_NAMES.name, names);
    }
  },
  async silInstrumentNames({ state, commit }) {
    if (!state.silValveNames.fresh) {
      const names = await listService.silDeviceNames(SilDeviceType.Instrument);
      commit(mutations.SET_SIL_INSTRUMENT_NAMES.name, names);
    }
  },
  async silIntelligentPstNames({ state, commit }) {
    if (!state.silValveNames.fresh) {
      const names = await listService.silDeviceNames(SilDeviceType.IntelligentPst);
      commit(mutations.SET_SIL_INTELLIGENTPST_NAMES.name, names);
    }
  },
  async actuatorFetchAll({ dispatch, rootGetters }): Promise<void> {
    const deviceData = rootGetters['sizing/deviceData'];

    if (!isEmpty(deviceData.actuator.series)) {
      await dispatch('springActions', true);
      await dispatch('actuatorSizes', true);
      await dispatch('springRanges', true);
    }
  },
  async insulationTypes({ commit }) {
    const value = await listService.insulationTypes();
    commit(mutations.SET_INSULATION_TYPES.name, value);
  },
  async reset({ commit, dispatch }) {
    //Remove separate reset mutation and do one unit reset mutation function
    // It's hard to read vuex store if there is too much happening
    commit(mutations.RESET_VALVE_TYPES.name);
    commit(mutations.RESET_VALVE_SERIES.name);
    commit(mutations.RESET_PRESSURE_CLASSES.name);
    commit(mutations.RESET_VALVE_SIZES.name);
    commit(mutations.RESET_SIZE_REDUCTIONS.name);
    commit(mutations.RESET_SEAT_CODES.name);
    commit(mutations.RESET_TRIM_OPTIONS.name);
    commit(mutations.RESET_DESIGN_STYLES.name);
    commit(mutations.RESET_FLOW_DIRECTIONS.name);
    commit(mutations.RESET_TRIM_CHARACTERISTICS.name);
    commit(mutations.RESET_SEAT_TYPES.name);
    commit(mutations.RESET_BEARING_MATERIALS.name);
    commit(mutations.RESET_PACKING_MATERIALS.name);
    commit(mutations.RESET_SEAT_AMOUNTS.name);
    commit(mutations.RESET_ATTENUATOR_TYPES.name);
    commit(mutations.RESET_ATTENUATOR_SIZES.name);
    commit(mutations.RESET_RESISTOR_SIZES.name);
    commit(mutations.RESET_SPRING_ACTIONS.name);
    commit(mutations.RESET_ACTUATOR_SIZES.name);
    commit(mutations.RESET_SPRING_RANGES.name);
    commit(mutations.RESET_BONNETTYPES.name);
    await dispatch('schedules');
    await dispatch('pipeSizes');
    await dispatch('valveTypes');
    await dispatch('valveSeries');
    await dispatch('pressureClasses');
  },
  valveDetailsReset({ commit }) {
    commit(mutations.RESET_VALVE_SIZES.name);
    commit(mutations.RESET_SIZE_REDUCTIONS.name);
    commit(mutations.RESET_SEAT_CODES.name);
    commit(mutations.RESET_TRIM_OPTIONS.name);
    commit(mutations.RESET_DESIGN_STYLES.name);
    commit(mutations.RESET_FLOW_DIRECTIONS.name);
    commit(mutations.RESET_TRIM_CHARACTERISTICS.name);
    commit(mutations.RESET_SEAT_TYPES.name);
    commit(mutations.RESET_BEARING_MATERIALS.name);
    commit(mutations.RESET_PACKING_MATERIALS.name);
    commit(mutations.RESET_SEAT_AMOUNTS.name);
    commit(mutations.RESET_ATTENUATOR_TYPES.name);
    commit(mutations.RESET_ATTENUATOR_SIZES.name);
    commit(mutations.RESET_RESISTOR_SIZES.name);
  },
  async fetchAllValveProperties({ dispatch, rootGetters }): Promise<void> {
    const valveMotion = rootGetters['sizing/valveMotion'];
    const props = [
      'valveSizes',
      'sizeReductions',
      'designStyles',
      'flowDirections',
      'seatCodes',
      'trimCharacteristics',
      'trimOptions'
    ];

    if (rootGetters['sizing/actuator_active']) {
      props.push('packingMaterials', 'seatTypes');

      if (valveMotion === ValveMotion.Rotary) {
        props.push('bearingMaterials', 'seatAmounts');
      } else if (valveMotion === ValveMotion.Linear) {
        props.push('bonnetTypes', 'seatLeakages', 'trimTypes');
      }
    }

    if (rootGetters['sizing/resistor_active']) {
      props.push('resistorSizes');
    }

    if (rootGetters['sizing/attenuator_active']) {
      props.push('attenuatorTypes', 'attenuatorSizes');
    }

    for (const prop of props) {
      await dispatch(prop, false);
    }
  },
  async setFluidTypes({ commit }): Promise<void> {
    commit(mutations.SET_FLUID_TYPES.name, cloneDeep(defaultFluidTypes));
  },
  async fetchInsulationTypes({ dispatch }): Promise<void> {
    await dispatch('insulationTypes', false);
  }
};

export const units: Module<UnitsState, RootState> = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
};

function isValidOption(key: string, value: any, optionsMap: Map<string, string>, state: UnitsState): boolean {
  if (!value || !optionsMap.has(key)) {
    return true;
  }

  const optionsName = optionsMap.get(key) as string;

  if (optionsName) {
    let options = (state as any)[optionsName];

    if (options?.data) {
      options = options.data;
    }

    if (!options || !Array.isArray(options)) {
      return false;
    }

    return options.some((x: { value: any; name: any }) =>
      x.value ? x.value.toString() === value.toString() : x.name === value.toString()
    );
  }

  return false;
}

function getInvalidProps(obj: { [key: string]: any }, optionsMap: Map<string, string>, state: UnitsState): string[] {
  const invalidProps: string[] = [];

  for (const key in obj) {
    const value = obj[key];

    if (!isValidOption(key, value, optionsMap, state)) {
      invalidProps.push(key);
    }
  }

  return invalidProps;
}

function getInvalidValveProps(obj: { [key: string]: any }, state: UnitsState): string[] {
  const valveMap = new Map<string, string>([
    ['series', 'valveSeries'],
    ['size', 'valveSizes'],
    ['pressureClass', 'pressureClasses'],
    ['sizeReduction', 'sizeReductions'],
    ['trimOption', 'trimOptions'],
    ['designStyle', 'designStyles'],
    ['flowDirection', 'flowDirections'],
    ['trimCharacteristic', 'trimCharacteristics'],
    ['seatCode', 'seatCodes'],
    ['seatType', 'seatTypes'],
    ['bearingMaterial', 'bearingMaterials'],
    ['packingMaterial', 'packingMaterials'],
    ['seatAmount', 'seatAmounts']
  ]);
  return getInvalidProps(obj, valveMap, state);
}

function getInvalidActuatorProps(obj: { [key: string]: any }, state: UnitsState): string[] {
  const actuatorMap = new Map<string, string>([
    ['safetyFactor', 'safetyFactorSelectionOptions'],
    ['series', 'actuatorSeries'],
    ['size', 'actuatorSizes'],
    ['springAction', 'springActions'],
    ['springRange', 'springRanges']
  ]);

  return getInvalidProps(obj, actuatorMap, state);
}

function torqueUnits(): SerieEntry[] {
  return [
    { name: 'Nm', type: ValveMotion.Rotary },
    { name: 'ftlb', type: ValveMotion.Rotary },
    { name: 'N', type: ValveMotion.Linear },
    { name: 'kgf', type: ValveMotion.Linear },
    { name: 'lbf', type: ValveMotion.Linear }
  ];
}

function convertUnit(targetUnit: 'in' | 'mm', sourceValue: number): number | undefined {
  if (typeof sourceValue === 'undefined') {
    return undefined;
  }

  const conversionTable: { inches: number; millimeters: number }[] = [
    { inches: 0.25, millimeters: 8 },
    { inches: 0.375, millimeters: 10 },
    { inches: 0.5, millimeters: 15 },
    { inches: 0.75, millimeters: 20 },
    { inches: 1, millimeters: 25 },
    { inches: 1.25, millimeters: 32 },
    { inches: 1.5, millimeters: 40 },
    { inches: 2, millimeters: 50 },
    { inches: 2.5, millimeters: 65 },
    { inches: 3, millimeters: 80 },
    { inches: 4, millimeters: 100 },
    { inches: 5, millimeters: 125 },
    { inches: 6, millimeters: 150 },
    { inches: 8, millimeters: 200 },
    { inches: 10, millimeters: 250 },
    { inches: 12, millimeters: 300 },
    { inches: 14, millimeters: 350 },
    { inches: 16, millimeters: 400 },
    { inches: 18, millimeters: 450 },
    { inches: 20, millimeters: 500 },
    { inches: 22, millimeters: 550 },
    { inches: 24, millimeters: 600 },
    { inches: 26, millimeters: 650 },
    { inches: 28, millimeters: 700 },
    { inches: 30, millimeters: 750 },
    { inches: 32, millimeters: 800 },
    { inches: 34, millimeters: 850 },
    { inches: 36, millimeters: 900 },
    { inches: 38, millimeters: 950 },
    { inches: 40, millimeters: 1000 },
    { inches: 42, millimeters: 1050 },
    { inches: 44, millimeters: 1100 },
    { inches: 48, millimeters: 1200 },
    { inches: 52, millimeters: 1300 },
    { inches: 54, millimeters: 1350 },
    { inches: 56, millimeters: 1400 },
    { inches: 60, millimeters: 1500 },
    { inches: 64, millimeters: 1600 },
    { inches: 68, millimeters: 1700 },
    { inches: 72, millimeters: 1800 },
    { inches: 76, millimeters: 1900 },
    { inches: 80, millimeters: 2000 }
  ];
  let row: { inches: number; millimeters: number } | undefined;

  if (targetUnit === 'mm') {
    row = conversionTable.find((row) => row.inches === sourceValue);

    if (row === undefined) {
      return undefined;
    }

    return row.millimeters;
  }

  if (targetUnit === 'in') {
    row = conversionTable.find((row) => row.millimeters === sourceValue);

    if (row === undefined) {
      return undefined;
    }

    return row.inches;
  }

  throw new Error(`Invalid target unit of ${targetUnit}`);
}

function pulpTypes(): Entry[] {
  return [{ name: PulpType.Kraft }, { name: PulpType.Recycled }, { name: PulpType.Mechanical }];
}

function bonnetTypes(): Entry[] {
  return [];
}
